import React from 'react';
import cx from 'classnames';
import _ from 'lodash';
import GlobalConst from '../../utils/GlobalConst';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';

class TextArea extends ShallowRenderedComponent {

	constructor(props) {
		super(props);
		this.state = {active: props.autoFocus};
		this.propsToOmitOnUpdate = ['onChange'];
	}

	value() {
		return this.input ? this.input.value : '';
	}

	handleFocus = () => {
		const { onFocus } = this.props;
		this.setState({active: true});
		onFocus && onFocus();
	};

	handleBlur = () => {
		const { onBlur } = this.props;
		this.setState({active: false});
		onBlur && onBlur();
	};

	onKeyDown = event => {
		const { onEnter, onEscape } = this.props;
		if(event.which === GlobalConst.ENTER_KEY) {
			onEnter && onEnter();
		} else if(event.which === GlobalConst.ESCAPE_KEY) {
			onEscape && onEscape();
		}
	};

	render() {
		const { className, placeholder, rows, value, onChange, autoFocus, error, disabled, readonly, id, style, helpBlockAbsolute, maxLength } = this.props;
		const { active } = this.state;
		const classes = cx('inputer', className, {'has-error': Boolean(error)});
		return (
			<div className={classes} style={style}>
				<div className={cx('input-wrapper', {active, disabled, readonly})}>
					<textarea id={id} rows={rows || 1} autoFocus={autoFocus} className={cx('form-control', {valid: !_.isEmpty(value)})}
						   placeholder={placeholder} value={value} onChange={onChange} maxLength={maxLength}
						   onFocus={this.handleFocus} onBlur={this.handleBlur} onKeyDown={this.onKeyDown} ref={node => this.input = node}/>
				</div>
				{error && <span className={cx('help-block', {absolute: helpBlockAbsolute})}><small>{error}</small></span>}
			</div>
		);
	}

}

export default TextArea;
