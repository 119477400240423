import { createBrowserHistory } from 'history';
import AnalyticsManager from './core/AnalyticsManager';

const history = createBrowserHistory();
let prevPathName = null;
history.listen(location => {
	if (prevPathName !== location.pathname) {
		prevPathName = location.pathname;
		AnalyticsManager.sendPageViewAnalytics();
	}
});
export default history;
