import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import $ from 'jquery';
import _ from 'lodash';
import { isPromise } from '../../utils/func';
import GlobalConst from '../../utils/GlobalConst';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';

class Button extends ShallowRenderedComponent {

	static propTypes = {
		formID: PropTypes.string,
		pendingTitle: PropTypes.any,
		href: PropTypes.string,
		isSmall: PropTypes.bool
	};

	static defaultProps = {
		isSmall: true
	};

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			...this.state
		};
		this.propsToOmitOnUpdate = ['onClick'];
	}

	componentDidMount() {
		super.componentDidMount();
		this.handleRippleAnimation();
		this.addFormSubmitListener();
	}

	componentWillUnmount() {
		super.componentWillUnmount();
		this.removeFormSubmitListener(this.props.formID);
	}

	componentDidUpdate(prevProps) {
		if(prevProps.formID !== this.props.formID) {
			this.removeFormSubmitListener(prevProps.formID);
			this.addFormSubmitListener();
		}
	}

	removeFormSubmitListener(formID) {
		if(formID) {
			$('#' + formID).off('keyup');
		}
	}

	addFormSubmitListener() {
		const { formID } = this.props;
		if(formID) {
			$('#' + formID).keyup((event) => {
				if(event.keyCode === GlobalConst.ENTER_KEY) {
					this.onClick();
				}
			});
		}
	}

	handleRippleAnimation() {
		let element, ripple, d, x, y;
		let i = 1;
		let queue = [];
		$(this.btn).on('click', function (e) {
			element = $(this);

			// remove old items from queue and DOM
			// allow max 5 to be loaded
			if (queue.length > 5) {
				$('._' + queue.shift()).remove();
			}

			// Assume user can't click more than 1000 times / second
			//terrible overflow protection.
			if (i > 1000) {
				i = 0;
			}

			// add next item to queue
			i++;
			queue.push(i);

			// Build element
			element.append('<span class="ripple _' + i + '"></span>');
			ripple = element.find('._' + i);

			// Make it big enough to cover whole parent
			if(!ripple.height() && !ripple.width()) {
				d = Math.max(element.outerWidth(), element.outerHeight());
				ripple.css({height: d, width: d});
			}

			// Get origin
			x = e.pageX - element.offset().left - ripple.width() / 2;
			y = e.pageY - element.offset().top - ripple.height() / 2 ;

			// Set location and animate
			ripple.css({top: y+'px', left: x+'px'}).addClass('animate');

			setTimeout(() => element.find('.ripple').remove(), 550);
		});
	}

	onClick = event => {
		const { onClick } = this.props;
		const { isLoading } = this.state;
		if(event) {
			event.stopPropagation();
		}
		if(onClick && !isLoading) {
			const promise = onClick(event);
			if(isPromise(promise)) {
				this.setState({ isLoading: true });
				promise.finally(() => this.mounted && this.setState({ isLoading: false }));
			}
		}
	};

	renderContent() {
		const { isLoading } = this.state;
		const { children, href, pendingTitle } = this.props;
		if(isLoading){
			return pendingTitle || 'Loading...';
		}
		return !_.isEmpty(href) ? <a className="inner-link" href={href} target="_blank">{children}</a> : children;
	}

	render() {
		const { className, type, id, isSmall, href, disabled, noClasses, ...rest } = this.props;
		const classes = cx({'btn btn-ripple':!noClasses}, className, {'btn-xs': isSmall});
		const restProps = _.omit(rest, ['pendingTitle', 'formID']);
		if(!_.isEmpty(href)) {
			return (
				<div id={id} className={`${classes} inner-link-container`} ref={node => this.btn = node}>
					{this.renderContent()}
				</div>
			)
		}
		return (
			<button id={id} type={type || 'button'} {...restProps} onClick={this.onClick} className={classes} disabled={disabled} ref={node => this.btn = node}>
				{this.renderContent()}
			</button>
		);
	}
}

export default Button;
