import React from 'react';
import PhoneInput from 'react-phone-number-input'

const PhoneInputField = props => {
    const setValue = e => props.onChange(e);
    return (
        <PhoneInput
            defaultCountry="US"
            placeholder={props.placeholder}
            countrySelectProps={{ unicodeFlags: true }}
            value={props.value}
            onChange={setValue}/>
    );
}

export default PhoneInputField;