import React, { Children } from 'react';
import PropTypes from 'prop-types';
import Button from './Button';
import cx from 'classnames';
import { isPromise } from '../../utils/func';
import _ from 'lodash';
import $ from 'jquery';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';

class SelectBox extends ShallowRenderedComponent {

	static propTypes = {
		title: PropTypes.any,
		pullRight: PropTypes.bool,
		maxMenuHeight: PropTypes.number,
		onSelect: PropTypes.func,
		selectedOptionsIndexes: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
		loadingTitle: PropTypes.string,
		extraControl: PropTypes.element,
		showCaret: PropTypes.bool,
		noClose: PropTypes.bool,
		dropUp: PropTypes.bool,
		inline: PropTypes.bool,
		disabled: PropTypes.bool,
		error: PropTypes.string,
		onClose: PropTypes.func,
		onOpen: PropTypes.func,
		mountImmediately: PropTypes.bool,
		dataTestId: PropTypes.string
	};

	static defaultProps = {
		selectedOptionsIndexes: null,
		loadingTitle: 'Updating...',
		showCaret: true,
		noClose: false,
		inline: false,
		maxMenuHeight: 450,
		disabled: false,
		dropUp: false,
		error: null,
		mountImmediately: false,
		showTrashIndex: null,
		showDeleteIndex: null,
		dataTestId: 'default'
	};

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			wasShown: false,
			...this.state
		};
	}

	componentDidMount() {
		this.$menu.find('.extra-control').on('click', () => {
			if (!this.props.noClose) {
				this.$menu.addClass('noclose');
				setTimeout(() => {
					this.$menu.removeClass('noclose');
				}, 1);
			}
		});
		this.$menu.on('click', event => {
			const $target = $(event.target);
			return !$target.hasClass('disabled');
		});
		this.$dropdown.on('hidden.bs.dropdown', () => {
			const { onClose } = this.props;
			if(onClose) {
				onClose();
			}
		});
		this.$dropdown.on('show.bs.dropdown', () => {
			const { onOpen } = this.props;
			if(onOpen) {
				onOpen();
			}
			this.setState({wasShown: true});
		});
	}

	componentWillUnmount() {
		this.$menu.off('click');
		this.$dropdown.off('hidden.bs.dropdown');
		this.$dropdown.off('show.bs.dropdown');
	}

	toggle() {
		this.$button.dropdown('toggle');
	}

	onSelect(index) {
		const { onSelect } = this.props;
		if(onSelect) {
			const promise = onSelect(index);
			if (isPromise(promise)) {
				this.setState({isLoading: true});
				promise.finally(() => this.setState({isLoading: false}));
			}
		}
	}

	isIndexSelected(index) {
		const { selectedOptionsIndexes } = this.props;
		if(_.isArray(selectedOptionsIndexes)) {
			return _.includes(selectedOptionsIndexes, index);
		}
		return selectedOptionsIndexes === index;
	}

	onTrashClick = (e, i) => {
		if (!e) e = window.event;
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
		return this.setState({ showDeleteIndex: i, showTrashIndex: null });
	}

	confirmDeleteClick = (e, index) => {
		if (!e) e = window.event;
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
		const { onSelectDelete, options } = this.props;
		if(onSelectDelete) {
			const promise = onSelectDelete(options[index]);
			if (isPromise(promise)) {
				this.setState({isLoading: true});
				promise.finally(() => this.setState({isLoading: false, showDeleteIndex: null, showTrashIndex: null}));
			}
		}
	}

	cancelDeleteClick = e => {
		if (!e) e = window.event;
		e.cancelBubble = true;
		if (e.stopPropagation) e.stopPropagation();
		return this.setState({ showDeleteIndex: null, showTrashIndex: null });
	}

	render() {
		const { className, title, children, pullRight, maxMenuHeight, loadingTitle, extraControl, hideTrashFirstChild, multiselect, dpClass, blockedOptions, showRadio,
			showCaret, noClose, inline, disabled, dropUp, error, mountImmediately, onSelectDelete, showCaretSub, plusLogo, currentOptionsIDs, hideSelect, showClear, onClear, dataTestId } = this.props;
		const { isLoading, wasShown, showTrashIndex, showDeleteIndex } = this.state;
		const classes = 'dropdown-toggle ' + (className || '');
		const isDeleteAvailable = typeof onSelectDelete === 'function';
		const noCurrentSelections = _.isNil(currentOptionsIDs) || _.isUndefined(currentOptionsIDs);
		const titleElement = (
			<span>
				{showCaret && !inline && _.isEmpty(showCaretSub) && <span className={cx('fa pull-right', {'fa-sort-down': !dropUp, 'fa-sort-up': dropUp})}/>}
				<span className="dropdown-title">{isLoading ? loadingTitle : title}</span>
				{showCaret && inline && _.isEmpty(showCaretSub) && <span style={{verticalAlign: 'text-top'}} className={cx('fa ml-5', {'fa-sort-down': !dropUp, 'fa-sort-up': dropUp})}/>}
                {showClear && <span onClick={onClear} className='clear-options material-symbols-outlined float-right'>close</span>}
				{!_.isEmpty(showCaretSub) && showCaretSub}
			</span>
		);
		return (
			<div className={cx('btn-group', {'btn-group-inline': inline, 'dropup': dropUp})} ref={node => this.$dropdown = $(node)} data-testid={`btn-${dataTestId}`}>
				{!inline && <Button className={classes} disabled={disabled} data-toggle="dropdown" ref={node => this.$button = $(node)}>{titleElement}</Button>}
				{inline && <a href="#" className={classes} data-toggle="dropdown" ref={node => this.$button = $(node)}>{titleElement}</a>}
				<ul className={cx(`dropdown-menu ${!_.isEmpty(dpClass)?dpClass:''}`, {'dropdown-menu-right': pullRight, noclose: noClose})}
					style={{maxHeight: maxMenuHeight ? maxMenuHeight + 'px' : null}}
					role="menu" data-testid={`dropdown-${dataTestId}`} ref={node => this.$menu = $(node)}>
					{extraControl && <li className="extra-control not-selectable">{extraControl}</li>}
					{(wasShown || mountImmediately) && Children.map(children, (child, i) => {
						if(child) {
							const isSelected = this.isIndexSelected(i);
							const isNotSelectable = _.includes(_.get(child, 'props.className'), 'not-selectable');
							const isBlocked = !_.isEmpty(blockedOptions) ? _.reduce(blockedOptions, (acc, item) => {
								if (_.includes(child.key, item)) acc = true;
								return acc;
							}, false) : false;
							const showTrashFirstChild = i===0 && hideTrashFirstChild;
							const checkboxOption = hideSelect || noCurrentSelections || isNotSelectable ? null :
								!multiselect && isSelected && showRadio ?  <div className='dp-checkmark-radio checked'><div className='custom-radio'></div></div> :
								!multiselect && !isSelected && showRadio ? <span className='material-symbols-outlined dp-checkmark-radio'>radio_button_unchecked</span> :
								!multiselect && isSelected && !showRadio ? <i className='material-icons checkmark'>check_box</i> :
								(!multiselect && !isSelected) ? null :
								isSelected ? <i className='material-icons checkmark checked'>check_box</i> : <i className='material-icons checkmark'>check_box_outline_blank</i>
							return (
								<li key={i} className={cx({selected: isSelected, 'not-selectable disabled-link': isNotSelectable, 'underline': showTrashFirstChild, 'no-spacing': noCurrentSelections, 'plus-logo': plusLogo, 'blocked-opt': isBlocked })}
									onClick={() => this.onSelect(i)} onMouseEnter={() => {if(isDeleteAvailable)this.setState({ showTrashIndex: i })}} onMouseLeave={() => {if(isDeleteAvailable)this.setState({ showTrashIndex: null })}}>
									{checkboxOption}
									{isDeleteAvailable && !showTrashFirstChild && showTrashIndex==i && showDeleteIndex==null && <i className="fa fa-trash" aria-hidden="true" onClick={e => this.onTrashClick(e, i)}></i>}
									{child}
									{isDeleteAvailable && <div className={cx('delete-confirmation', {'expand-delete':showDeleteIndex==i})}>
										<span className='head'>Delete?</span>
										<button className='confirm' onClick={e => this.confirmDeleteClick(e, i)}>Yes</button>
										<button className='cancel' onClick={e => this.cancelDeleteClick(e)}>No</button>
									</div>}
								</li>
							);
						}
						return null;
					})}
				</ul>
				{error && <span className="dropdown-error help-block"><small>{error}</small></span>}
			</div>
		);
	}

}

export default SelectBox;
