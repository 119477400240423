import _ from 'lodash';

export class NotificationItem {

	static fromJSON(json){
		return _.extend(new this(), {
			id: json.id,
			createdAt: json.created,
			updatedAt: json.updated,
			platform: json.platform,
			message: json.message,
			link: json.params?.link,
			linkText: json.params?.link_text,
			type: json.type,
			lastEnabled: json.last_enabled,
			editedBy: json.edited_by,
			isEnabled: json.is_enabled
		});
	}

	static toJSON(json){
		return _.extend(new this(), {
			is_enabled: json.isEnabled,
			type: json.type,
			params: {
				link: json.link,
				link_text: json.linkText
			},
			platform: json.platform,
			message: json.message
		});
	}

}

export class UserNotificationItem {

	static fromJSON(json){
		return _.extend(new this(), {
			id: json.id,
			notificationID: json.notification_id,
			createdAt: json.created,
			updatedAt: json.updated,
			platform: json.platform,
			message: json.display_message,
			link: json.params?.link,
			linkText: json.params?.link_text,
			type: json.type,
			isRead: json.read
		});
	}

}
