import _ from 'lodash';
import { SocialNetworkStatistic } from './BaseModels'
import { normalizeDataWithValueKey } from '../core/chartsDataMappers';
import { sliceCountryData, trafficSourcesTitles } from '../utils/func';
import dateUtil from '../utils/dates';
import GlobalConst from '../utils/GlobalConst';
import { AWHistoricalData } from './AdWordsModels';

class BaseVideo {

	static fromJSON(json){
		let genData = json.general_data || {};
		let customProperties = json.custom_properties || {};
		return _.extend(new this(), {
			category: json.category,
			consideration: json.consideration,
			country: json.country,
			blocked: customProperties.blocklist || json.blocklist,
			description: json.description,
			details: json.details ? {
				engagement: json.details.engagement || json.details.engage_rate,
				sentiment: json.details.sentiment,
				views: json.details.views,
				thirtyDaysViews: json.details.thirty_days_views,
				youtube: SocialNetworkStatistic.fromJSON(json.details.youtube),
				earnings: json.details.earnings,
				facebook: SocialNetworkStatistic.fromJSON(json.details.facebook),
				googlePlus: json.details.googlePlus,
				total: json.details.total,
				comments: json.details.comments,
				likes: json.details.likes
			} : {},
			id: json.id,
			isMonetizable: (json.monetization || {}).is_monetizable || false,
			tags: json.tags,
			title: json.title,
			updatedAt: dateUtil.dateToLocalZone(json.updated_at),
			contentOwner: json.content_owner,
			ptk: json.ptk,
			language: genData.language,
			status: json.status,
			subStatus: json.sub_status,
			monetizer: json.monetizer,
			fromAdWords: json.from_ad_words,
			audience: json.analytics ? {
				ages: json.analytics.age ? Object.keys(json.analytics.age).map(title => ({title: title, value: json.analytics.age[title]})) : {},
				countries: json.analytics.country ? sliceCountryData(json.analytics.country) : {},
				genders: json.analytics.gender ? Object.keys(json.analytics.gender).map(title => ({title: title, value: json.analytics.gender[title], name: title})) : {},
				demographicItems: json.analytics.audience ? json.analytics.audience : {}
			} : {},
			trafficSources: (json.analytics && json.analytics.traffic_source) ?
				Object.keys(json.analytics.traffic_source).map((key) => {
					return {
						source: key,
						title: trafficSourcesTitles[key],
						value: json.analytics.traffic_source[key]
					}
			}) : [],
			adWordsData: json.ads_stats ? AWHistoricalData.fromJSON(json.ads_stats) : {}
		});
	}

	get tagClass() {
		return 'yt-tag';
	}

	get source() {
		if(this instanceof YTVideo) {
			return GlobalConst.EXTERNAL_SOURCE_YOUTUBE;
		}
		return -1;
	}

	get isYoutube() {
		return this instanceof YTVideo;
	}

}

export class YTVideo extends BaseVideo {

	static fromJSON(json){
		let stats = json.stats || {};
		let flags = stats.flags || [];
		let genData = json.general_data || {};
		let channelData = json.channel || {};
		let iabCategories = genData.primary_category ? [genData.primary_category] : genData.iab_categories;
		return _.extend(super.fromJSON(json), {
			chartData: {
				views: normalizeDataWithValueKey(json.chart_data, 'views', 'created_at', true),
				likes: normalizeDataWithValueKey(json.chart_data, 'likes', 'created_at', true),
				comments: normalizeDataWithValueKey(json.chart_data, 'comments', 'created_at', true),
				engagement: normalizeDataWithValueKey(json.chart_data, 'engage_rate', 'created_at', true),
				sentiment: normalizeDataWithValueKey(json.chart_data, 'sentiment', 'created_at', true)
			},
			category: !_.isEmpty(iabCategories) ? iabCategories : genData.category,
			iabCategories: iabCategories,
			primaryCategory: genData.primary_category,
			channel: {
				title: channelData.title,
				id: channelData.id,
				thumbnailImageUrl: channelData.thumbnail_image_url
			},
			cmsTitle: json.analytics ? json.analytics.cms_title : null,
			comments: stats.comments || '',
			consideration: json.consideration,
			country: genData.country,
			days: json.days,
			description: genData.description,
			details: json.details,
			duration: genData.duration,
			engageRate: stats.engage_rate || null,
			externalLink: `https://www.youtube.com/watch?v=${json.main.id}`,
			id: json.main.id,
			isMonetizable: json.monetization ? json.monetization.is_monetizable: null,
			likes: stats.likes || null,
			sentiment: stats.sentiment || null,
			tags: genData.tags,
			thumbnailImageUrl: genData.thumbnail_image_url,
			title: genData.title,
			updatedAt: dateUtil.dateToLocalZone(json.main.updated_at),
			views: stats.views || null,
			viewsPerDay: stats.views_per_day || null,
			thirtyDaysViews: stats.last_30day_views || null,
			weeklyViews: stats.last_7day_views || null,
			dailyViews: stats.last_day_views || null,
			thirtyDaysLikes: stats.last_30day_likes || null,
			weeklyLikes: stats.last_7day_likes || null,
			dailyLikes: stats.last_day_likes || null,
			thirtyDaysComments: stats.last_30day_days_comments || null,
			weeklyComments: stats.last_7day_comments || null,
			dailyComments: stats.last_day_comments || null,
			verified: !!json.analytics,
			videoSafety: json.video_safety,
			videoSafetyStatus: json.video_safety_status,
			titleSafetyStatus: json.video_title_safety_status,
			titleBadWords: json.title_bad_words,
			titleBadStatuses: json.title_bad_statuses,
			transcript: json.transcript,
			badWords: json.bad_words,
			brandSafety: json.brand_safety,
			brandSafetyData: json.brand_safety_data,
			youtubePublishedAt: dateUtil.dateToLocalZone(genData.youtube_published_at),
			license: genData.license,
			isOwner: json.is_owner,
			flags:  {
				isStrangeEngageRate: flags.includes('is_strange_engage_rate'),
				isStrangeSentiment: flags.includes('is_strange_sentiment'),
				isStrangeViews: flags.includes('is_strange_views'),
				mostWatched: flags.includes('most_watched'),
				mostLiked: flags.includes('most_liked'),
				trending: flags.includes('trending'),
				viral: flags.includes('viral')
			},
			isFlagged: !!(json.stats && json.stats.flags),
			hasAWData: !!json.ads_stats,
			score: json.score,
			vettedState: json.vetted_status
		});
	}

}

export class FlaggedVideo {

	static fromJSON(json){
		return _.extend(new this(), {
			blocked: json.blocklist,
			id: json.id,
			thumbnailImageUrl: json.thumbnail_image_url,
			title: json.title,
			transcript: json.transcript,
			categoryFlaggedWords: json.category_flagged_words,
			totalFlaggedWords: json.total_unique_flagged_words,
			worstWords: json.worst_words,
			brandSafetyData: {
				score: json.score,
				label: json.label
			},
			engageRate: json.engage_rate,
			viewsCount: json.views,
			createdAt: json.youtube_published_at
		});
	}

}
