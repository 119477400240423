import _ from 'lodash';
import dates from '../../utils/dates';
import { thousandsSeparated } from '../../utils/numbers';

export function mapCountFilter(filter, itemsName = '', allOption=false) {
	let result = _.map(filter, item => ({title:item.key, id: item.key, description: _.isEmpty(itemsName) ? `${thousandsSeparated(item.doc_count)}` : `${thousandsSeparated(item.doc_count)} ${itemsName}` }));
	if (allOption) {
		result = [{id: null, title: 'All'}].concat(result);
	}
	return result;
}

export function singleCountFilter(title, count) {
	let result = {title: title, id: true, description: `${thousandsSeparated(count)}` };
	return [result];
}

export function mapCountBucketFilter(filter, itemsName = '', allOption=false, reverse=false) {
	let result = _.map(filter, (bucket) => {
		const name = bucket.title || bucket.key;
		return {
			id: bucket.key,
			title: capitalizeName(name),
			description: _.isEmpty(itemsName) ? `${thousandsSeparated(bucket.doc_count)}` : `${thousandsSeparated(bucket.doc_count)} ${itemsName}`
		}
	});
	if (allOption) {
		result = [{id: null, title: 'All'}].concat(result);
	}
	if (reverse) {
		return result.reverse();
	}
	return result;
}

export function mapBooleanSingleCheckboxCountFilter(filter, trueValueTitle, itemsName = '') {
	let result = _.transform(filter, (acc, [ value, count ]) => {
		if(count > 0) {
			const isTrue = Boolean(value);
			if (value) acc.push({id: String(isTrue), title: trueValueTitle, description: _.isEmpty(itemsName) ? `${thousandsSeparated(count)}` : `${thousandsSeparated(count)} ${itemsName}` });
		}
	}, []);
	return result;
}

export function mapBooleanSingleCheckboxCountBucketFilter(filter, trueValueTitle, itemsName = '') {
	let result = _.transform(filter, (acc, bucket) => {
		if(bucket.doc_count > 0) {
			const isTrue = Boolean(bucket.key);
			if (bucket.key) acc.push({id: String(isTrue), title: trueValueTitle, description: _.isEmpty(itemsName) ? `${thousandsSeparated(bucket.doc_count)}` : `${thousandsSeparated(bucket.doc_count)} ${itemsName}` });
		}
	}, []);
	return result;
}

export function mapRangeFilter(filter, outlier = null) {
	if(_.isEmpty(filter)) {
		return {};
	}
	const [ min, max ] = filter;
	if(_.isNumber(min) && _.isNumber(max) && max > min) {
		const result = {min, max};
		if(outlier) {
			result.outlier = _.transform(Object.keys(outlier), (acc, key) => {
				let value = outlier[key];
				if(min !== value && _.every(acc, v => v !== value)) {
					acc[`${key}%`] = value;
				}
			}, {});
		}
		return result;
	}
	return {};
}

export function mapDatesFilter(filter) {
	if(_.isEmpty(filter)) {
		return {};
	}
	const formatDate = dateStr => dateStr ? dates.formatToDatePicker(new Date(dateStr)) : null;
	const [ min, max ] = filter;
	return {start: formatDate(min), end: formatDate(max)};
}

export function mapBooleanCountFilter(filter, trueValueTitle, falseValueTitle, itemsName = '', trueIcon = null, falseIcon = null) {
	let result = _.transform(filter, (acc, [ value, count ]) => {
		if(count > 0) {
			const isTrue = Boolean(value);
			acc.push({id: String(isTrue), title: isTrue ? trueValueTitle : falseValueTitle, description: _.isEmpty(itemsName) ? `${thousandsSeparated(count)}` : `${thousandsSeparated(count)} ${itemsName}`, icon: isTrue ? trueIcon ? trueIcon : null : falseIcon ? falseIcon : null });
		}
	}, []);
	if(!_.isEmpty(result)) {
		result = [{id: null, title: 'All'}].concat(result);
	}
	return result;
}

export function mapBucketFilter(filter, allOption=false, reverse=false) {
	let result = _.map(filter, (bucket) => {
		const name = bucket.title || bucket.key;
		return {
			id: bucket.key,
			title: capitalizeName(name)
		}
	});
	if (allOption) {
		result = [{id: null, title: 'All'}].concat(result);
	}
	if (reverse) {
		return result.reverse();
	}
	return result;
}

export const capitalizeName = str => {
    const strSplit = str.split(' ');
    for (var i = 0, x = strSplit.length; i < x; i++) {
		if (!_.isEmpty(strSplit[i])) strSplit[i] = strSplit[i][0].toUpperCase() + strSplit[i].substr(1);
    }
    return strSplit.join(' ');
}

export const sortObjKeysAlphabetically = obj => {
	var ordered = {};
	Object.keys(obj).sort().forEach(function(key) {
		ordered[key] = obj[key];
	});
	return ordered;
}

export function objectToQueryString(object, trimFirstAmp = false) {
	let result = _.reduce(object, (acc, value, key) => {
		if(_.isArray(value) && !_.isEmpty(value)) {
			return acc.concat(_.map(value, v => `&${key}=${encodeURIComponent(v)}`).join(''));
		} else if(!_.isUndefined(value) && !_.isNull(value) && !_.isObject(value) && !(_.isString(value) && _.isEmpty(value))) {
			return acc.concat(`&${key}=${encodeURIComponent(value)}`);
		}
		return acc;
	}, '');
	if (trimFirstAmp) {
		result = _.trimStart(result, '&');
	}
	return result;
}

export function hasValue(val) {
	return !_.isNil(val) && !_.isUndefined(val);
}

export function onChangeInputFilter(currentFilters, type, val) {
	const updatedFilters = { ...currentFilters };
	if (val.hasOwnProperty('min')) {
		if (!_.isNumber(val.min)&&updatedFilters[type].hasOwnProperty('min')) {
			delete updatedFilters[type].min;
		} else if (_.isNumber(val.min)) {
			if (_.isEmpty(updatedFilters[type])) updatedFilters[type] = {};
			updatedFilters[type].min = val.min;
		}
	}
	if (val.hasOwnProperty('max')) {
		if (!_.isNumber(val.max)&&updatedFilters[type].hasOwnProperty('max')) {
			delete updatedFilters[type].max;
		} else if (_.isNumber(val.max)) {
			if (_.isEmpty(updatedFilters[type])) updatedFilters[type] = {};
			updatedFilters[type].max = val.max;
		}
	}
	if (_.isEmpty(updatedFilters[type])) {
		delete updatedFilters[type];
	}
	return updatedFilters;
}

export function generateMinMaxString(str, noNull=false) {
	const strSplit = str.split(', ');
	if (!_.isEmpty(strSplit[0]) && !_.isEmpty(strSplit[1])) return ({min: strSplit[0], max: strSplit[1]});
	else if (!_.isEmpty(strSplit[0], 3) && _.isEmpty(strSplit[1])) return ({min: strSplit[0], max: noNull ? '' : null});
	else if (_.isEmpty(strSplit[0]) && !_.isEmpty(strSplit[1])) return ({min: noNull ? '' : null, max: strSplit[1]});
}
