import _ from 'lodash';

export class SocialNetworkStatistic {

	static fromJSON(json) {
		if(!json) {
			return new this();
		}
		return _.extend(new this(), {
			followers: json.followers,
			comments: json.comments || json.comments_per_video,
			likes: json.likes || json.likes_per_video,
			engagementsPerVideo: json.engagements_per_video,
			commentsPerVideo: json.comments_per_video,
			likesPerVideo: json.likes_per_video,
			viewsPerVideo: json.views_per_video
		});
	}

}

export class Person {

	get fullName() {
		if(this.firstName || this.lastName){
			return `${this.firstName || ''} ${this.lastName || ''}`;
		}
		return this.username;
	}

	get name() {
		return this.fullName || this.email || 'Unknown';
	}

	static fromJSON(json) {
		return _.extend(new this(), {
			firstName: json.first_name,
			lastName: json.last_name,
			email: json.email,
			username: json.username,
			googleAccountId: json.google_account_id,
			id: json.id
		});
	}
}

export class PagedResponse {

	static fromJSON(json, ItemClass) {
		if (_.isNumber(json.max_watch)) {
			return _.extend(new this(), {
				page: json.current_page,
				items: _.map(json.items, ItemClass.fromJSON.bind(ItemClass)),
				itemsCount: json.items_count,
				totalPages: json.max_page,
				filters: json.filters,
				sorts: json.sorts,
				watching: json.watching,
				maxWatch: json.max_watch,
				columns: json.columns
			});
		} else if (_.isArray(json.columns)) {
			return _.extend(new this(), {
				page: json.current_page,
				items: _.map(json.items, ItemClass.fromJSON.bind(ItemClass)),
				itemsCount: json.items_count,
				totalPages: json.max_page,
				filters: json.filters,
				sorts: json.sorts,
				columns: json.columns
			});
		}
		return _.extend(new this(), {
			page: json.current_page,
			items: _.map(json.items, ItemClass.fromJSON.bind(ItemClass)),
			itemsCount: json.items_count,
			totalPages: json.max_page,
			filters: json.filters,
			sorts: json.sorts
		});
	}

}

export class YTPagedResponse {

	static fromJSON(json, ItemClass) {
		return _.extend(new this(), {
			nextPageToken: json.next_page_token,
			itemsCount: json.items_count,
			items: _.map(json.items, ItemClass.fromJSON.bind(ItemClass))
		})
	}

}

export class Country {

	static fromJSON(json) {
		return _.extend(new this(), {
			code: json.id,
			common: json.common,
			official: json.official,
			id: json.id,
			title: json.common,
			pk: json.pk
		});
	}

}
