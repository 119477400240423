import browserHistory from '../history';
import _ from 'lodash';
import UserManager from './UserManager';
import queryString from 'query-string';
import { matchPath } from 'react-router';
import GlobalConst from '../utils/GlobalConst';
import PubSub from 'pubsub-js';

export default {

	goBack() {
		browserHistory.goBack();
	},

	goBackWithPath(path=null) {
		if (document.referrer!='') this.goTo(path);
		else this.goBack();
	},

	currentQuery() {
		return queryString.parse(browserHistory.location.search);
	},

	getQueryKeyValue(key, url = window.location.href) {
		const name = key.replace(/[\[\]]/g, '\\$&');
		var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
			results = regex.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2]);
	},

	removeFromQuery(keys) {
		if (!_.isEmpty(keys)) {
			browserHistory.replace({
				pathname: this.currentPath(),
				query: queryString.stringify(_.omit(this.currentQuery(), keys))
			});
		}
	},

	currentPath() {
		return browserHistory.location.pathname;
	},

	currentHost() {
		return browserHistory.location.host;
	},

	currentPathMatches(path) {
		const match = matchPath(this.currentPath(), {path});
		return !_.isNil(match);
	},

	goTo(path, query = null) {
		if(!query) {
			browserHistory.push(path);
		} else {
			browserHistory.push({pathname: path, search: queryString.stringify(query)});
		}
		PubSub.publish(GlobalConst.SET_BREADCRUMBS_MSG)
	},

	goToExternal(path, newWindow=false) {
		if (!newWindow) {
			window.open(path);
		} else {
			window.open(path, '_blank');
		}
	},

	replaceQuery(newQuery) {
		browserHistory.replace({
			pathname: this.currentPath(),
			search: queryString.stringify(newQuery)
		})
	},

	replaceRawQuery(newQuery) {
		browserHistory.replace({
			pathname: this.currentPath(),
			search: newQuery
		})
	},

	goToBrandRegister() {
		this.goTo('/brand_register');
	},

	goToCreatorRegister() {
		this.goTo('/creator_register');
	},

	goToUserCabinet() {
		this.goTo('/my_profile');
	},

	goToWelcomePage() {
		this.goTo('/welcome');
	},

	goToErrorPage() {
		this.goTo('/maintenance');
	},

	goToUserList() {
		this.goTo('/admin/users');
	},

	goToLogin(checkFrom = false, error = null) {
		const query = {};
		if(checkFrom){
			query.from = location.pathname + location.search;
		}
		if(error){
			query.error= error;
		}
		this.goTo('/login', query);
	},

	goToDefaultRoute() {
		if (UserManager.user.isActive) return this.goTo(this.takeHome());
		else if (!UserManager.user.isActive && UserManager.user.email) return this.goToWelcomePage();
		else if (!UserManager.isAuthorized) return this.goToLogin();
	},

	takeHome() {
		return '/home';
	},

	internalChannelLink(id) {
		const path = this.currentPath();
		if(/insights\/channels$/i.test(path)) {
			return `${path}/${id}`;
		}
		if(/my_profile\/channels$/i.test(path)) {
			return `${path}/${id}`;
		}
		if(/insights\/videos\/(?!.*\/).*/i.test(path)) {
			return `/insights/channels/${id}`;
		}
		if(/insights\/videos$/i.test(path)) {
			return `/insights/channels/${id}`;
		}
		return `/insights/channels/${id}`;
	},

	vettingToolLink(id) {
		return `/build/vetting_tool/${id}`;
	},

	vettingToolLinkResearch(id, type, toResearch=false) {
		if (toResearch) return `/insights/${type}s`;
		return `/insights/${type}s/vetting_tool/${id}`;
	},

	internalVideoLink(id) {
		const path = this.currentPath();
		if(/insights\/videos$/i.test(path)) {
			return `${path}/${id}`;
		}
		if(/insights\/channels/i.test(path)) {
			return `/insights/videos/${id}`;
		}
		if(/insights\/keywords/i.test(path)) {
			return `/insights/videos/${id}`;
		}
		if(/my_profile\/channels/i.test(path)) {
			return `/my_profile/videos/${id}`;
		}
		return `/insights/videos/${id}`;
	},

	internalKeywordLink(id) {
		const path = this.currentPath();
		if(/insights\/keywords$/i.test(path)) {
			return `${path}/${id}`;
		}
		return `/insights/keywords/${id}`;
	},

	internalSegmentLink(type, id) {
		return `/media_planning/custom_segments/${type}/${id}`;
	},

	internalAWAccountLink(id) {
		const path = this.currentPath();
		if(/reporting$/i.test(path)) {
			return `${path}/${id}`;
		}
		if(/reporting\/(?!.*\/).*/i.test(path)) {
			return path;
		}
		if(/accounts$/i.test(path)) {
			return `${path}/${id}/`;
		}
		return '/reporting/';
	},

	internalAWAccountSetupLink(accountID, campaignID = null) {
		let res = `/analytics/self_service/${accountID}/setup`;
		if(campaignID) {
			res += `?campaignID=${campaignID}`;
		}
		return res;
	},

	internalUserPermissionsPageLink(userID){
		return `/admin/users/permissions/${userID}/`;
	},

	internalRolePermissionsPageLink(roleID){
		return `/admin/roles/permissions/${roleID}/`;
	},

	accessDeniedPageURL() {
		return '/access_denied';
	},

	setTitle(title) {
		document.title = title ? `${title} | ViewIQ` : 'ViewIQ';
	}

};

