import React from 'react';
import _ from 'lodash';
import dateUtil from '../utils/dates';
import GlobalConst from '../utils/GlobalConst';

export function normalizeDataWithValueKey(data, valueKey = 'value', dateKey = 'created_at', setDayStart = false) {
	if(!data) {
		return [];
	}
	const converter = setDayStart ? dateUtil.dateToLocalZoneDayStart : dateUtil.dateToLocalZone;
	return _.map(data, record => {
		return {value: Number(record[valueKey]), date: converter(record[dateKey])};
	});
}

export function padDateSeries(data, padValue = null, grouping = GlobalConst.CHART_GROUPING_BY_DAYS) {
	const normalized = normalizeDateSeries(_.map(data, 'date'), -1, grouping);
	return _.map(normalized, date => {
		const record = _.find(data, r => r.date.getTime() === date.getTime());
		return {date: date, value: record ? record.value : padValue};
	});
}

//madfix according to SAAS-448. SHOULD BE FIXED LATER !!!!!!
export function normalizeLocalDataWithValueKey(data, valueKey = 'value', dateKey = 'created_at') {
	if(!data) {
		return [];
	}
	return _.map(data, record => {
		return {value: Number(record[valueKey]), date: dateUtil.dateWithoutZone(record[dateKey])};
	});
}

export function normalizeDataWithValueKeyFromObject(object) {
	if(!object) {
		return [];
	}
	const result = [];
	_.forOwn(object, (value, key) => result.push({value: Number(value), date: dateUtil.dateToLocalZone(key)}));

	return result;
}

export function normalizeDateSeries(dates, minRecordsCount = 0, grouping = GlobalConst.CHART_GROUPING_BY_DAYS) {
	if(!dates || dates.length === 0) {
		return [];
	}
	dates.sort((a, b) => b - a);
	const lastDate = _.last(dates);
	const firstDate = _.first(dates);
	const result = [];
	for(let i = 0; i < 999999; i++) {
		const date = minRecordsCount === -1 ? new Date(firstDate) : new Date();
		const currentMonth = date.getMonth();
		const currentDay = date.getDate();
		const currentHour = date.getHours();
		if(grouping === GlobalConst.CHART_GROUPING_BY_MONTHS) {
			date.setMonth(currentMonth - i, 1);
			date.setDate(1);
			date.setHours(0, 0, 0, 0);
		} else if(grouping === GlobalConst.CHART_GROUPING_BY_DAYS) {
			date.setDate(currentDay - i);
			date.setHours(0, 0, 0, 0);
		} else {
			date.setHours(currentHour - i, 0, 0, 0);
		}
		result.push(date);
		if(date.getTime() <= lastDate.getTime() && i >= minRecordsCount) {
			break;
		}
	}
	return result.reverse();
}

export function prepareSummaryChartData(data, recordsCount = 0, grouping = GlobalConst.CHART_GROUPING_BY_DAYS, trimLastDate = false) {
	const  dateFormatter = grouping === GlobalConst.CHART_GROUPING_BY_MONTHS ? dateUtil.formatToMonthsOnlyFormat : dateUtil.formatToMonthsAndDaysFormat;
	let prevValue = null;
	const normalized = normalizeDateSeries(_.map(data, 'date'), recordsCount, grouping);
	const template = _.map(normalized, date => {
		return {dateStr: dateFormatter(date), dateObj: date, value: null, change: null, changePercent: null}
	});

	let result = _.transform(data, (accumulator, record) => {
		const formattedDate = dateFormatter(record.date);
		const existed = _.find(accumulator, record => record.dateStr === formattedDate);
		if(existed) {
			if(record.date.getTime() >= existed.dateObj.getTime()) {
				existed.value = record.value;
				existed.dateObj = record.date;
			}
		}
	}, template);

	_.each(result, record => {
		if(record.value !== null) {
			if(prevValue !== null) {
				record.change = record.value - prevValue;
				record.changePercent = prevValue != 0 ? record.change / prevValue : 0;
			}
			prevValue = record.value;
		}
	});

	if(recordsCount > 0) {
		result = _.takeRight(result, recordsCount);
	}
	if(trimLastDate) {
		result = _.initial(result);
	}
	return result;
}


export function prepareTrafficSourcesData(trafficSources, primarySourcesCount) {
	const sorted = _.sortBy(trafficSources, 'value').reverse();
	const primarySources = _.take(sorted, primarySourcesCount);
	const otherSources = _.takeRight(sorted, _.size(sorted) - primarySourcesCount);
	const otherSourcesSum = _.sumBy(otherSources, 'value');
	if(otherSourcesSum > 0) {
		return primarySources.concat({title: 'Other', value: otherSourcesSum});
	}
	return primarySources;
}

export function prepareTotalAnd30DaysChartData(item) {
	if (!item || item.views === 0 && item.thirtyDaysViews === 0) {
		return null;
	}
	return [
		{title: 'Total', value: item.views, color: '#CC1919'},
		{
			title: 'Last 30 days',
			value: item.thirtyDaysViews, color: '#363636'
		}
	]
}


export function prepareStripChartData(data, icons, colors) {
	return _.map(data, (item, i) => {
		return {
			title: <span className={`fa ${icons[item.title]}`} title={item.title}/>,
			value: item.value,
			color: colors[i]
		}
	});
}

export function arrayToTimeSeries(array, daysShift = 0) {
	const startDate = new Date();
	startDate.setUTCHours(23, 59, 59, 999);
	return _.map(array, (value, i) => {
		startDate.setUTCDate(daysShift + i);
		return {value, date: new Date(startDate)};
	});
}
