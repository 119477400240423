import moment from 'moment';

const dateTimeDisplayFormat = 'MMMM Do YYYY, HH:mm';
const dateTimeDisplayFormatAMPM = 'MMMM Do YYYY, hh:mm A';
const dateOnlyDisplayFormat = 'MMM Do YYYY';
const shortDateFormat = 'MM/DD/YYYY';
const shortestDateFormat = 'MM/DD/YY';
const shortDateFormatMonthDay = 'MM/DD';
const shortDateFormatDash = 'MM-DD-YYYY';
const shortDateTimeFormat = 'MM/DD/YYYY HH:mm';
const shortDateTimeFormatAMPMat = 'MM/DD/YYYY [at] hh:mm A';
const apiFormat = 'YYYY-MM-DD'; // iso
const monthsOnlyFormat = 'MMM\'YY';
const monthsNoYearFormat = 'MMM';
const monthsLongOnlyFormat = 'MMMM YYYY';
const monthsAndDaysFormat = 'DD MMM \'YY';
const hoursOnlyFormat = 'DD MMM HH:00';
const shortDisplayFormat = 'DD MMM\'YY HH:mm';
const shortDisplayFormatAMPM = 'MMM DD YYYY [\r\n] hh:mm A';
const shortDisplayFormatAMPMat = 'MMM DD, YYYY [at] hh:mm A';
const monthsAndDaysOnlyFormat = 'DD MMM';
const monthsAndDaysOnlyFormatApi = 'DD-MM';
const dayMonthYearFormat = 'DD MMM YYYY';
const onlyAMPMHours = 'LT';
const shortDateFormatRegular = 'L';
const formatDateForDisplaying = (stringDate) => moment(stringDate).format(dateTimeDisplayFormat);
export default {
	displayApiFormat: (date) => moment(date).format(apiFormat),
	displayFormat: formatDateForDisplaying,
	displayFormatAMPM: (date) => moment(date).format(dateTimeDisplayFormatAMPM),
	displayFormatDateTime: (date) => formatDateForDisplaying(date),
	fromNow: (date) => moment(date).fromNow(),
	formatToDayMonthYearFormat: (date) => moment(date).format(dayMonthYearFormat),
	formatToUpdatedFormat: (date) => 'Updated: ' + formatDateForDisplaying(date),
	formatToOnlyDateFormat: (date) => moment(date).format(dateOnlyDisplayFormat),
	formatToShortDateFormat: (date) => moment(date).format(shortDateFormat),
	formatToShortestDateFormat: (date) => moment(date).format(shortestDateFormat),
	formatToShortDateFormatRegular: (date) => moment(date).format(shortDateFormatRegular),
	formatToShortDateFormatMonthDay: (date) => moment(date).format(shortDateFormatMonthDay),
	formatToShortDateFormatDash: (date) => moment(date).format(shortDateFormatDash),
	formatToShortDateTimeFormat: (date) => moment(date).format(shortDateTimeFormat),
	formatToShortDisplayFormat: (date) => moment(date).format(shortDisplayFormat),
	formatToMonthsNoYearDisplayFormat: (date) => moment(date).format(monthsNoYearFormat),
	formatToShortDisplayFormatAMPM: (date) => moment(date).format(shortDisplayFormatAMPM),
	formatToShortDisplayFormatAMPMat: (date) => moment(date).format(shortDisplayFormatAMPMat),
	formatToShortDateTimeFormatAMPMat: (date) => moment(date).format(shortDateTimeFormatAMPMat),
	formatToDisplayOnlyAMPMHours: (date) => moment(date).format(onlyAMPMHours),
	formatToBackendFormat: (date) => moment.utc(date).format(apiFormat),
	formatToDatePicker: (date) => moment.utc(date).format(shortDateFormat),
	formatToLocalBackendFormat: (date) => moment(date).format(apiFormat), // use it for AW Accounts
	formatToMonthsOnlyFormat: (date) => moment(date).format(monthsOnlyFormat),
	formatToMonthsLongOnlyFormat: (date) => moment(date).format(monthsLongOnlyFormat),
	formatToMonthsAndDaysFormat: (date) => moment(date).format(monthsAndDaysFormat),
	formatToHoursOnlyFormat: (date) => moment(date).format(hoursOnlyFormat),
	momentFromNow: (date) => moment(date).momentFromNow(),
	monthOnlyFormat: date => moment(date).format('MMMM'),
	monthsAndDaysOnlyFormat: (date) => moment(date).format(monthsAndDaysOnlyFormat),
	monthsAndDaysOnlyFormatApi: (date) => moment(date).format(monthsAndDaysOnlyFormatApi),
	monthsAndDaysCommaFormat: (date) => moment(date).format('ll'),
	dateToLocalZone(inputDate) {
		return inputDate && new Date(moment.utc(inputDate).local().format());
	},
	dateToLocalZoneDayStart(inputDate) {
		return inputDate && new Date(moment.utc(inputDate).set({'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0}).local().format());
	},
	dateWithoutZone(inputDate) { // use it for AW Accounts
		return inputDate && new Date(moment(inputDate).format());
	},
	momentStartOfCurrentYear() {
		return moment().startOf('year');
	},
	momentStartOfCurrentMonth() {
		return moment().startOf('month');
	},
	momentStartOfCurrentDay() {
		return moment().startOf('day');
	},
	secondsToTime(seconds) {
		let secs = seconds;
		const pad = num => ('0' + num).slice(-2);
		let minutes = Math.floor(secs / 60);
		secs = secs % 60;
		let hours = Math.floor(minutes / 60);
		minutes = minutes % 60;
		return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
	},
	momentFromDays(days) {
		return moment().subtract(days, 'days').toDate();
	},
	momentToDays(days) {
		return moment().add(days, 'days').toDate();
	},
	momentIsBetween(date, startDate, endDate) {
		return moment(date).isBetween(startDate, endDate);
	},
	daysLeft(date) {
		const a = moment(date);
		const b = moment().today;
		return a.diff(b, 'days');
	},
	secondsToMinutes(seconds) {
		let secs = seconds.toFixed(0);
		const pad = num => ('0' + num).slice(-2);
		let minutes = Math.floor(secs / 60);
		secs = secs % 60;
		return `${pad(minutes)}:${pad(secs)}`;
	},
	currentYearQuarter() {
		return moment().quarter();
	},
	dateToUTC(inputDate) {
		return new Date(moment.utc(inputDate).format());
	},
	UTCNow() {
		return this.dateToUTC(new Date());
	},
	dateMatchesPreviousYear(year_str) {
		return year_str == new Date().getFullYear() - 1;
	},
	dateMatchesActualYear(year_str){
		return year_str == new Date().getFullYear();
	},
	datePreviousYear(){
		return new Date().getFullYear() - 1;
	},
	dateActualYear(){
		return new Date().getFullYear();
	}
}
