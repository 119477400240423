// Google tag (gtag.js) logic for Google Analytics
import UtilsDataProvider from '../components/common/UtilsDataProvider';
import UserManager from '../core/UserManager';

const AnalyticsManager = {
	sendPageViewAnalytics: () => {
        const url = `${location.origin}${location.pathname}`;
		const user = UserManager.user.email;
		let environment;
		if (location.host.includes('localhost') || location.host.includes('rc.viewiq')) environment = 'DEV';
		else if (location.host == 'viewiq') environment = 'PROD';
		const userType = UserManager.user.userType || null;
		if (window.gtag) {
			let userId = user ? UserManager.user.email.replace('@', '_').split('.') : '-';
			if (userId != '-') {
				userId.pop();
				userId = userId.join('_');
			}
            window.gtag('config', 'G-T8VKV96293', {
                custom_map: {
					dimension1: userId,
					dimension2: environment,
					dimension3: userType
				},
                send_page_view: false
            });
            window.gtag('event', 'page_view', {
                page_location: url
            });
		}
		if (UserManager.isAuthorized && !UserManager.user.isActive) {
			return;
		}

		const slug = location.pathname.split('/')[1] || 'home page';
		/*eslint-disable no-console */
		if(UserManager.authToken) UtilsDataProvider.createUserAction({ slug, url }).catch(err => console.error(err));
		/*eslint-enable no-console */
	}
};

export default AnalyticsManager;
