import React from 'react';
import PropTypes from 'prop-types';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import { ModalV2, ModalHeaderV2 } from '../markup/ModalV2';
import Button from './Button';
import UserManager from '../../core/UserManager';

class OAuthSyncConfirmModal extends ShallowRenderedComponent {

	static propTypes = {
		onCloseModal: PropTypes.func,
		isUnlinking: PropTypes.bool
	}

	constructor(props) {
		super(props);
		this.state = {
			userEmail: UserManager.user.email,
			firstName: UserManager.user.firstName,
			lastName: UserManager.user.lastName
		};
		this.initialState = {...this.state};
	}

	synchronize = () => {
		const { onCloseModal } = this.props;
		onCloseModal(true);
	}

	cancelSync = () => {
		const { onCloseModal } = this.props;
		onCloseModal(false);
	}

	returnToAccount = () => {
		const { onCloseModal } = this.props;
		onCloseModal(false);
		UserManager.loginAsOriginalUser();
	}

	render() {
		const { firstName, lastName, userEmail} = this.state;
		const { isUnlinking } = this.props;
		return <ModalV2 id='sync-confirm-modal' className='sync-confirm-modal' style={{ width: '480px' }} noCloseOutside={false}>
			<ModalHeaderV2 className='modal-header'>{isUnlinking ? 'Unlink' : 'Sync' } Impersonated User</ModalHeaderV2>
			<div className='text-container'>
				<div className='modal-text'>
					Warning! You are logged in as user {firstName} {lastName}
					<br /> ({userEmail}), and not yourself.
				</div>
				{!isUnlinking && <div className='modal-text'>
					Syncing your own ad platform account with a client’s account is forbidden due to data privacy reasons.
				</div>}
				<div className='modal-text'>
					Are you sure you want to continue?
				</div>
			</div>
			<Button className='btn btn-ripple yes-btn mb-5 btn-xs he-30 br-2' onClick={this.synchronize}>Yes</Button>
			<Button className='btn btn-ripple cancel-btn mb-5 btn-xs he-30 br-2' onClick={this.cancelSync}>Cancel</Button>
			<Button className='btn btn-ripple return-btn mb-5 btn-xs he-30 br-2' onClick={this.returnToAccount}>Go back to your account</Button>
		</ModalV2>;
	}
}

export default OAuthSyncConfirmModal;
