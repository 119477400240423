import React from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';
import Input from './Input';
import cx from 'classnames';
import SelectBox from './SelectBox';
import _ from 'lodash';
import BasicOptionsPicker from './BasicOptionsPicker';
import CFLogoRed from '../../img/cf-logo-red.png';

class Dropdown extends BasicOptionsPicker {

	static propTypes = {
		...SelectBox.propTypes,
		...BasicOptionsPicker.propTypes,
		showFiltering: PropTypes.bool,
		options: PropTypes.array,
		currentOptionsIDs: PropTypes.any,
		maxOptions: PropTypes.number,
		multiselect: PropTypes.bool,
		block: PropTypes.bool,
        placeholder: PropTypes.string,
		blockedOptions: PropTypes.array,
		alternateDeleteSearch: PropTypes.bool,
		clearSearchSubscribeID: PropTypes.string,
		showRadio: PropTypes.bool,
		dataTestId: PropTypes.string
	};

	static defaultProps = {
		...BasicOptionsPicker.defaultProps,
		...SelectBox.defaultProps,
		multiselect: false,
		showRadio: false,
		alternateTitle: null,
		dataTestId: 'default'
	};

	constructor(props) {
		super(props);
		this.state = {
			filter: '',
			...this.state
		};
		this.propsToOmitOnUpdate = ['onSelect', 'onClose', 'onOpen'];
	}

	componentDidMount() {
		const { clearSearchSubscribeID } = this.props;
		if (!_.isEmpty(clearSearchSubscribeID)) {
			PubSub.subscribe(clearSearchSubscribeID, () => {
				this.filterOptions('');
			})
		}
	}

	componentWillUnmount() {
		const { clearSearchSubscribeID } = this.props;
		if (!_.isEmpty(clearSearchSubscribeID)) {
			PubSub.unsubscribe(clearSearchSubscribeID);
		}
	}

	toggle() {
		this.selectBox.toggle();
	}

	filterOptions = str => {
		const filter = _.trimStart(str);
		this.setState({ filter });
	};

	optionByID = optionID => {
		return _.find(this.props.options, opt => this.getOptionID(opt) === optionID);
	};

	getFilteredOptions() {
		const { options, showFiltering } = this.props;
		const { filter } = this.state;
		if(!showFiltering) {
			return options;
		}
		const checker = text => text?.toLowerCase().indexOf(filter.toLowerCase()) > -1;
		return _.filter(options, opt => {
			const title = this.getOptionTitle(opt);
			const description = this.getOptionDescription(opt);
			return _.isEmpty(filter) || checker(title) || (description && checker(description));
		});
	}

	onOptionSelected = optionIndex => {
		const { onSelect, multiselect, currentOptionsIDs, idKey='id', options, sendOptionsIDs=false } = this.props;
		if (!_.isEmpty(options)) {
			const option =  this.getFilteredOptions()[optionIndex];
			if(onSelect && option && !option.disabled) {
				if(multiselect&&sendOptionsIDs) {
					return onSelect(_.xor(currentOptionsIDs, [option.id]));
				} else if (multiselect) {
					const currentOptions = _.map(currentOptionsIDs, this.optionByID);
					return onSelect(_.xorBy(currentOptions, [option], idKey));
				}
				return onSelect(option);
			}
		}
	};

	renderOptions() {
		const { maxOptions, options, plusLogo, customEmptyMessage, descriptionUnder } = this.props;
		const { filter } = this.state;
		let filtered = this.getFilteredOptions();
		if(!_.isEmpty(filtered)) {
			const preMaxFiltered = JSON.parse(JSON.stringify(filtered));
			if(maxOptions) {
				filtered = _.take(filtered, maxOptions);
			}
			let elements = _.map(filtered, (option, i) => {
				const description = this.getOptionDescription(option);
				const title = this.getOptionTitle(option);
				const img = this.getOptionImg(option);
				return (
					<span className={cx({disabled: option ? option.disabled : false,'description-under':description&&descriptionUnder})} key={`${this.getOptionID(option)}_${i}`} title={option ? option.tooltip || this.getOptionTitle(option) : null}>
						{(plusLogo && option.segment_id !== null) && <img src={CFLogoRed} className='logo-opt' />}
						{img}
						{option?.editable ? <input id={`selectboxinput-${i}`} className='selectbox-edit-input' defaultValue={title} onChange={e => option.editableFunc(e)} maxLength={120} /> : title}
						{description && <small className="option-description" css-content={description}>{description}</small>}
					</span>);
			});
			if (!_.isEmpty(filter) && preMaxFiltered.length > filtered.length) {
				elements = elements.concat(<span key="hidden-options-count" className='not-selectable'><small><i>{preMaxFiltered.length - filtered.length} options hidden</i></small></span>);
			} else if (options.length > filtered.length && preMaxFiltered.length !== filtered.length) {
				elements = elements.concat(<span key="hidden-options-count" className='not-selectable'><small><i>{options.length - filtered.length} options hidden</i></small></span>);
			}
			return elements;
		}
		return <span className='not-selectable'><small><i>{customEmptyMessage ? customEmptyMessage : 'No options'}</i></small></span>
	}

	selectedOptionsIndexes() {
		const { currentOptionsIDs } = this.props;
		const indexFinder = currentOption => _.findIndex(this.getFilteredOptions(), opt => this.getOptionID(opt) === currentOption);
		if(_.isArray(currentOptionsIDs)) {
			return _.map(currentOptionsIDs, indexFinder);
		}
		return indexFinder(currentOptionsIDs);
	}

	renderTitle() {
		const { title, multiselect, currentOptionsIDs, alternateTitle } = this.props;
		if(_.isNil(title)) {
			if(!multiselect) {
				const option = this.optionByID(currentOptionsIDs);
				return this.getOptionTitle(option) || alternateTitle;
			}
			const titles = _.map(currentOptionsIDs, id => this.getOptionTitle(this.optionByID(id)));
			return titles.join(', ') || alternateTitle;
		}
		return title;
	}

	onSelectAll = () => {
		const { selectAllFunc } = this.props;
		return selectAllFunc && selectAllFunc(this.getFilteredOptions(), 'select');
	}

	onDeselectAll = () => {
		const { deselectAllFunc } = this.props;
		return deselectAllFunc && deselectAllFunc(this.getFilteredOptions(), 'deselect');
	}

	render() {
		const { showFiltering, extraControl, selectAllOptions, placeholder, alternateDeleteSearch } = this.props;
		const { filter } = this.state;
		let extra = null;
		let selectOptions = null;
		if (selectAllOptions) {
			selectOptions = <div>
				<a onClick={this.onSelectAll}>Select All</a>
				&nbsp;&nbsp;
				<a onClick={this.onDeselectAll}>Deselect All</a>
			</div>;
		}
		if(showFiltering || extraControl) {
			extra = <div>
				{showFiltering && <Input placeholder={placeholder || 'Filter...'} value={filter} onChange={this.filterOptions} noCross={false} alternateDeleteSearch={alternateDeleteSearch}/>}
				{extraControl}
				{selectOptions}
			</div>
		}
		return (
			<SelectBox {...this.props} onSelect={this.onOptionSelected} ref={node => this.selectBox = node} selectedOptionsIndexes={this.selectedOptionsIndexes()}
				title={this.renderTitle()} extraControl={extra}>
				{this.renderOptions()}
			</SelectBox>
		);
	}

}

export default Dropdown;
