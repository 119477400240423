import _ from 'lodash';

export class GoogleAdsCIDModel {
	static fromJSON(json){
		return _.extend(new this(), {
            type: 'cid',
            id: json.id,
            name: json.name,
            access: json.access_granted,
            hasCampaigns: json.is_active
		});
	}
}

export class GoogleAdsCampaignModel {
	static fromJSON(json){
		return _.extend(new this(), {
            type: 'campaign',
            id: json.id,
            name: json.name,
            parentID: json.account
		});
	}
}

export class GoogleAdsAdGroupModel {
	static fromJSON(json){
		return _.extend(new this(), {
            type: 'adgroup',
            id: json.id,
            name: json.name,
            parentID: json.campaign,
            displayName: json.display_name || json.name
		});
	}
}
