import { PagedResponse } from '../models/BaseModels';
import _ from 'lodash';
import Promise from 'bluebird';
import $ from 'jquery';
import UserManager from './UserManager';
import RouterHelper from './RouterHelper';
import { Segment } from '../models/SegmentModel';

const addTrailingSlash = (url) => url.replace(/\/?(\?|#|$)/, '/$1');

export class BaseDataProvider {

	getCookie(name) {
		var value = '; ' + document.cookie;
		var parts = value.split('; ' + name + '=');
		if (parts.length === 2) return parts.pop().split(';').shift();
	}

	objectToQueryString(object, trimFirstAmp = false) {
		let result = _.reduce(object, (acc, value, key) => {
			if(_.isArray(value) && !_.isEmpty(value)) {
				return acc.concat(_.map(value, v => `&${key}=${encodeURIComponent(v)}`).join(''));
			} else if(!_.isUndefined(value) && !_.isNull(value) && !_.isObject(value) && !(_.isString(value) && _.isEmpty(value))) {
				return acc.concat(`&${key}=${encodeURIComponent(value)}`);
			}
			return acc;
		}, '');
		if (trimFirstAmp) {
			result = _.trimStart(result, '&');
		}
		return result;
	}

	objectToQueryStringFullArray(object, trimFirstAmp = false, formatNested = false) {
		let result = _.reduce(object, (acc, value, key) => {
			if(_.isArray(value) && !_.isEmpty(value)) {
				return acc.concat(`&${key}=${JSON.stringify(value)}`);
			} else if(!_.isUndefined(value) && !_.isNull(value) && !_.isObject(value) && !(_.isString(value) && _.isEmpty(value))) {
				return acc.concat(`&${key}=${encodeURIComponent(value)}`);
			} else if(_.isObject(value) && formatNested) {
				if (value.min) acc = acc.concat(`&min_${key}=${value.min}`);
				if (value.max) acc = acc.concat(`&max_${key}=${value.max}`);
			}
			return acc;
		}, '');
		if (trimFirstAmp) {
			result = _.trimStart(result, '&');
		}
		return result;
	}

	parseError(jqXHR, textStatus, resolve, reject, url, method) {
		let responseStatus = -1;
		let responseText = textStatus;
		if (jqXHR) {
			responseStatus = jqXHR.status;
			responseText = jqXHR.responseText;
			if (responseStatus === 401) {
				UserManager.clearAuthData();
				RouterHelper.goToLogin(true, 'Unauthorized request');
			} else if (responseStatus >= 200 && responseStatus <= 299) {
				try {
					resolve(responseText && responseText.length > 0 ? JSON.parse(responseText) : '');
				} catch (e) {
					resolve({});
				}
				return;
			}
		}

		let json = null;
		try {
			json = JSON.parse(responseText);
		} catch (e) {
		}
		reject({responseText, responseStatus, textStatus, json, url, method});
	}

	ajax(method, url, data, authorized = true, contentType = 'application/json; charset=utf-8') {
		var self = this;
		return new Promise((resolve, reject, cancel) => {
			let headers = null;
			if(authorized) {
				headers = {
					'X-CSRFToken': self.getCookie('csrftoken'),
					'Authorization': UserManager.authToken ? `Token ${UserManager.authToken}` : ''
				};
			}
			let xhr = $.ajax({
				type: method,
				url: url,
				data: data ? JSON.stringify(data) : null,
				headers,
				contentType: contentType,
				dataType: 'json',
				success(data) {
					resolve(data);
				},
				error(jqXHR, textStatus) {
					self.parseError(jqXHR, textStatus, resolve, reject, url, method);
				}
			});
			cancel(() => xhr.abort());
		});
	}

	ajaxLogin(method, url, data, authorized = true) {
		var self = this;
		return new Promise((resolve, reject, cancel) => {
			let headers = null;
			if(authorized) {
				headers = {
					'X-CSRFToken': self.getCookie('csrftoken'),
					'Authorization': UserManager.authToken ? `Token ${UserManager.authToken}` : ''
				};
			}
			let xhr = $.ajax({
				type: method,
				url: url,
				data: data ? JSON.stringify(data) : null,
				headers,
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				success(data) {
					resolve(data);
				},
				error(jqXHR, textStatus) {
					if (jqXHR.status == 500 || jqXHR.status == 501 || jqXHR.status == 502 || jqXHR.status == 503 || jqXHR.status == 504) {
						RouterHelper.goToErrorPage();
					}
					self.parseError(jqXHR, textStatus, resolve, reject, url, method);
				}
			});
			cancel(() => xhr.abort());
		});
	}

	fileUpload(url, file) {
		const self = this;
		const data = new FormData();
		data.append('file', file[0]);
		return new Promise((resolve, reject, cancel) => {
			let xhr = $.ajax({
				url: url,
				data: data,
				cache: false,
				contentType: false,
				processData: false,
				type: 'POST',
				headers: {
					'X-CSRFToken': self.getCookie('csrftoken'),
					'Authorization': UserManager.authToken ? `Token ${UserManager.authToken}` : ''
				},
				success(data) {
					resolve(data);
				},
				error(jqXHR, textStatus) {
					self.parseError(jqXHR, textStatus, resolve, reject, url, 'POST');
				}
			});
			cancel(() => xhr.abort());
		});
	}

	multipleFilesUpload(url, files) {
		const self = this;
		const data = new FormData();
		_.forEach(files, file => data.append(file[0], file[1]));
		return new Promise((resolve, reject, cancel) => {
			let xhr = $.ajax({
				url: url,
				data: data,
				cache: false,
				contentType: false,
				processData: false,
				type: 'POST',
				headers: {
					'X-CSRFToken': self.getCookie('csrftoken'),
					'Authorization': UserManager.authToken ? `Token ${UserManager.authToken}` : ''
				},
				success(data) {
					resolve(data);
				},
				error(jqXHR, textStatus) {
					self.parseError(jqXHR, textStatus, resolve, reject, url, 'POST');
				}
			});
			cancel(() => xhr.abort());
		});
	}

	imageUpload(url, img) {
		const self = this;
		return new Promise((resolve, reject, cancel) => {
			let xhr = $.ajax({
				url: url,
				data: img,
				cache: false,
				contentType: 'image/png',
				processData: false,
				type: 'POST',
				headers: {
					'X-CSRFToken': self.getCookie('csrftoken'),
					'Authorization': UserManager.authToken ? `Token ${UserManager.authToken}` : ''
				},
				success(data) {
					resolve(data);
				},
				error(jqXHR, textStatus) {
					self.parseError(jqXHR, textStatus, resolve, reject, url, 'POST');
				}
			});
			cancel(() => xhr.abort());
		});
	}

	fileWithDataUpload(url, data, type = 'POST') {
		const self = this;
		const formData = new FormData();

		_.forOwn(data, (value, key) => {
			formData.append(key, value)
		});

		return new Promise((resolve, reject, cancel) => {
			let xhr = $.ajax({
				url: url,
				data: formData,
				cache: false,
				contentType: false,
				processData: false,
				type: type,
				headers: {
					'X-CSRFToken': self.getCookie('csrftoken'),
					'Authorization': UserManager.authToken ? `Token ${UserManager.authToken}` : ''
				},
				success(data) {
					resolve(data);
				},
				error(jqXHR, textStatus) {
					self.parseError(jqXHR, textStatus, resolve, reject, url, 'POST');
				}
			});
			cancel(() => xhr.abort());
		});
	}

	fileDownload(url, requestBody, method='POST') {
		//http://stackoverflow.com/a/23797348 solution is used here
		return new Promise((resolve, reject, cancel) => {
			const xhr = new XMLHttpRequest();
			xhr.open(method, url, true);
			xhr.responseType = 'arraybuffer';
			xhr.setRequestHeader('Authorization', UserManager.authToken ? `Token ${UserManager.authToken}` : '');
			xhr.onload = function () {
				if (this.status === 200 || this.status === 201) {
					let filename = '';
					const disposition = xhr.getResponseHeader('Content-Disposition');
					if (disposition && disposition.indexOf('attachment') !== -1) {
						const fileNameStrip1 = disposition.replace('attachment; filename=', '');
						filename = fileNameStrip1.slice(1, fileNameStrip1.length-1);
					}
					const type = xhr.getResponseHeader('Content-Type');

					const blob = new Blob([this.response], {type: type});
					if (typeof window.navigator.msSaveBlob !== 'undefined') {
						// IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created.
						// These URLs will no longer resolve as the data backing the URL has been freed."
						window.navigator.msSaveBlob(blob, filename);
					} else {
						const URL = window.URL || window.webkitURL;
						const downloadUrl = URL.createObjectURL(blob);

						if (filename) {
							// use HTML5 a[download] attribute to specify filename
							const a = document.createElement('a');
							// safari doesn't support this yet
							if (typeof a.download === 'undefined') {
								window.location = downloadUrl;
							} else {
								a.href = downloadUrl;
								a.download = filename;
								document.body.appendChild(a);
								a.click();
							}
						} else {
							window.location = downloadUrl;
						}

						setTimeout(function () {
							URL.revokeObjectURL(downloadUrl);
						}, 100); // cleanup
					}
					resolve();
				} else {
					reject('Error while downloading file');
				}
			};
			xhr.setRequestHeader('Content-type', 'application/json');
			xhr.send(JSON.stringify(requestBody));
			cancel(() => xhr.abort());
		});
	}

	put(url, data, authorized = true) {
		return this.ajax('PUT', url, data, authorized);
	}

	patch(url, data, authorized = true) {
		return this.ajax('PATCH', url, data, authorized);
	}

	post(url, data, authorized = true, contentType) {
		return this.ajax('POST', url, data, authorized, contentType);
	}

	postLogin(url, data, authorized = true) {
		return this.ajaxLogin('POST', url, data, authorized);
	}

	get(url, authorized = true) {
		return this.ajax('GET', url, null, authorized);
	}

	delete(url, data, authorized = true) {
		return this.ajax('DELETE', addTrailingSlash(url), data, authorized);
	}

	deserializePagedResponse(response, ItemClass, ResponseClass = PagedResponse) {
		return ResponseClass.fromJSON(response, ItemClass);
	}

	mapResponseItemsToObject(items, ItemClass) {
		const obj = {};
		_.each(items || [], item => { obj[item.id] = ItemClass.fromJSON(item); });
		return obj;
	}

	segmentTypeToQueryParam(type) {
		switch(type) {
			case Segment.TYPES.CHANNEL:
				return 'channel';
			case Segment.TYPES.VIDEO:
				return 'video';
			case Segment.TYPES.KEYWORD:
				return 'keyword';
		}
		return null;
	}

}

export default BaseDataProvider;
