import _ from 'lodash';
import { areComponentsEqual as areComponentsEqualHot } from 'react-hot-loader';
import GlobalConst from './GlobalConst';

export function isPromise(promise) {
	return promise ? _.isFunction(promise.finally) : false;
}

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));

const countryCodes = [
	'ad',
	'ae',
	'af',
	'ag',
	'ai',
	'al',
	'am',
	'ao',
	'aq',
	'ar',
	'as',
	'at',
	'au',
	'aw',
	'ax',
	'az',
	'ba',
	'bb',
	'bd',
	'be',
	'bf',
	'bg',
	'bh',
	'bi',
	'bj',
	'bl',
	'bm',
	'bn',
	'bo',
	'bq',
	'br',
	'bs',
	'bt',
	'bv',
	'bw',
	'by',
	'bz',
	'ca',
	'cc',
	'cd',
	'cf',
	'cg',
	'ch',
	'ci',
	'ck',
	'cl',
	'cm',
	'cn',
	'co',
	'cr',
	'cu',
	'cv',
	'cw',
	'cx',
	'cy',
	'cz',
	'de',
	'dj',
	'dk',
	'dm',
	'do',
	'dz',
	'ec',
	'ee',
	'eg',
	'eh',
	'er',
	'es',
	'et',
	'eu',
	'fi',
	'fj',
	'fk',
	'fm',
	'fo',
	'fr',
	'ga',
	'gb-eng',
	'gb-nir',
	'gb-sct',
	'gb-wls',
	'gb',
	'gd',
	'ge',
	'gf',
	'gg',
	'gh',
	'gi',
	'gl',
	'gm',
	'gn',
	'gp',
	'gq',
	'gr',
	'gs',
	'gt',
	'gu',
	'gw',
	'gy',
	'hk',
	'hm',
	'hn',
	'hr',
	'ht',
	'hu',
	'id',
	'ie',
	'il',
	'im',
	'in',
	'io',
	'iq',
	'ir',
	'is',
	'it',
	'je',
	'jm',
	'jo',
	'jp',
	'ke',
	'kg',
	'kh',
	'ki',
	'km',
	'kn',
	'kp',
	'kr',
	'kw',
	'ky',
	'kz',
	'la',
	'lb',
	'lc',
	'li',
	'lk',
	'lr',
	'ls',
	'lt',
	'lu',
	'lv',
	'ly',
	'ma',
	'mc',
	'md',
	'me',
	'mf',
	'mg',
	'mh',
	'mk',
	'ml',
	'mm',
	'mn',
	'mo',
	'mp',
	'mq',
	'mr',
	'ms',
	'mt',
	'mu',
	'mv',
	'mw',
	'mx',
	'my',
	'mz',
	'na',
	'nc',
	'ne',
	'nf',
	'ng',
	'ni',
	'nl',
	'no',
	'np',
	'nr',
	'nu',
	'nz',
	'om',
	'pa',
	'pe',
	'pf',
	'pg',
	'ph',
	'pk',
	'pl',
	'pm',
	'pn',
	'pr',
	'ps',
	'pt',
	'pw',
	'py',
	'qa',
	're',
	'ro',
	'rs',
	'ru',
	'rw',
	'sa',
	'sb',
	'sc',
	'sd',
	'se',
	'sg',
	'sh',
	'si',
	'sj',
	'sk',
	'sl',
	'sm',
	'sn',
	'so',
	'sr',
	'ss',
	'st',
	'sv',
	'sx',
	'sy',
	'sz',
	'tc',
	'td',
	'tf',
	'tg',
	'th',
	'tj',
	'tk',
	'tl',
	'tm',
	'tn',
	'to',
	'tr',
	'tt',
	'tv',
	'tw',
	'tz',
	'ua',
	'ug',
	'um',
	'un',
	'unknown',
	'us',
	'uy',
	'uz',
	'va',
	'vc',
	've',
	'vg',
	'vi',
	'vn',
	'vu',
	'wf',
	'ws',
	'ye',
	'yt',
	'za',
	'zm',
	'zw'
];
export const trafficSourcesTitles = {
    'ADVERTISING': 'The viewer was referred to the video by an advertisement.',
    'ANNOTATION': 'Viewers reached the video by clicking on an annotation in another video.',
    'CAMPAIGN_CARD': 'Views originated from claimed, user-uploaded videos that the content owner used to promote the viewed content.',
    'END_SCREEN': ' The views were referred from the end screen of another video.',
    'EXT_URL': 'The video views were referred from a link on another website.',
    'NO_LINK_EMBEDDED': 'The video was embedded on another website when it was viewed.',
    'NO_LINK_OTHER': 'YouTube did not identify a referrer for the traffic.',
    'NOTIFICATION': 'The video views were referred from an email or notification from YouTube.',
    'PLAYLIST': 'The video views occurred while the video was being played as part of a playlist.',
    'PROMOTED': 'The video views were referred from an unpaid YouTube promotion.',
    'RELATED_VIDEO': 'The video views were referred from a related video listing on another video watch page.',
    'SUBSCRIBER': 'The video views were referred from feeds on the YouTube homepage or from YouTube subscription features.',
    'YT_CHANNEL': 'The video views occurred on a channel page.',
    'YT_OTHER_PAGE': 'The video views were referred from a link other than a search result or related video link that appeared on a YouTube page.',
    'YT_PLAYLIST_PAGE': 'The video views originated from a page that lists all of the videos in a playlist.',
    'YT_SEARCH': 'The video views were referred from YouTube search results.'
};

export function countryImage(countryCode) {
	if(_.isString(countryCode)) {
		const lowered = countryCode.toLowerCase();
		if (_.includes(countryCodes, lowered)) {
			return `https://viewiq-ui-assets.s3.amazonaws.com/flags/${lowered}.svg`;
		}
	}
	return 'https://viewiq-ui-assets.s3.amazonaws.com/flags/unknown.svg';
}

export function generateAbbreviation(string) {
	if(!_.isString(string)) {
		return '';
	}
	return string.match(/\b([a-z])/gi).join('').toUpperCase();
}

export function areComponentsEqual(component1, component2) {
	return areComponentsEqualHot(component1, component2);
}

export function stringifyFileSize(number) {
	const oneKB = 1024;
	const oneMB = oneKB*oneKB;
	if(number < oneKB) {
		return number + 'bytes';
	} else if(number > oneKB && number < oneMB) {
		return (number/oneKB).toFixed(1) + 'KB';
	} else if(number > oneMB) {
		return (number/oneMB).toFixed(1) + 'MB';
	}
}

export function imageBase64ToBlob(img) {
	const data = atob((img).substring('data:image/jpeg;base64,'.length));
	const asArray = new Uint8Array(data.length);

	for (let i = 0, len = data.length; i < len; ++i) {
		asArray[i] = data.charCodeAt(i);
	}

	return new Blob([asArray.buffer], {type: 'image/png'});
}

export function sliceCountryData(data) {
	var keysSorted = Object.keys(data).sort(function(a,b){return data[b]-data[a]});

	var sliced_data = keysSorted.slice(0, 10).map(key => ({
			code: key,
			title: key,
			value: data[key]
	}));
	return sliced_data;

}

export const checkFileExtension = (filename, extension) => {
	return filename.split('.').pop() === extension;
}

export const checkCSVFile = file => {
	return file?.name?.split('.')?.pop() === 'csv';
}

export const populateComscoreNodes = (treeData, nodeIDs) => {
	if (_.isEmpty(treeData) || _.isEmpty(nodeIDs)) return [];
	const nodesArray = [];
	const checkSelectedNodes = node => {
		_.each(node.children, child => {
			if (_.includes(nodeIDs, child.id)) nodesArray.push(child);
			else if (!_.isEmpty(child.children)) checkSelectedNodes(child);
		})
	}
	_.each(treeData['us'], node => {
		if (_.includes(nodeIDs, node.id)) nodesArray.push(node);
		else if (!_.isEmpty(node.children)) checkSelectedNodes(node);
	})
	return nodesArray;
}

export const createComscoreTreeStrings = (treeData, region) => {
	if (_.isEmpty(treeData)) return {};
	const stringsList = {};
	_.each(treeData[region], nodeOne => {
		// nodeOne (viewwing behaviours, finance, etc) is not a selectable node hence not added to the search
		if (!_.isEmpty(nodeOne.children)) {
			_.each(nodeOne.children, nodeTwo => {
				stringsList[`${nodeOne.value} > ${nodeTwo.value}`] = {  value: nodeTwo.value, id: nodeTwo.id, parentNodes: [nodeOne]};
				if (!_.isEmpty(nodeTwo.children)) {
					_.each(nodeTwo.children, nodeThree => {
						stringsList[`${nodeOne.value} > ${nodeTwo.value} > ${nodeThree.value}`] = {  value: nodeThree.value, id: nodeThree.id, parentNodes: [nodeOne, nodeTwo]};
						if (!_.isEmpty(nodeThree.children)) {
							_.each(nodeThree.children, nodeFour => {
								stringsList[`${nodeOne.value} > ${nodeTwo.value} > ${nodeThree.value} > ${nodeFour.value}`] = { value: nodeFour.value, id: nodeFour.id, parentNodes: [nodeOne, nodeTwo, nodeThree]};
							});
						}
					});
				}
			});
		}
	});
	return stringsList;
}

export const checkPureObject = obj => typeof obj === 'object' && !Array.isArray(obj) && obj !== null;

export const isNumeric = (value) => /^-?\d+$/.test(value);

// Build methods
import { Segment } from '../models/SegmentModel';
import { thousandsSeparated } from './numbers';

export const findPresetFilter = (steps, paramKey) => {
	let keyFilter = null;
	_.each(steps, stepValue => {
		_.each(stepValue.filters, filterValue => {
			let keyToCheck = filterValue.paramsID;
			if (_.isArray(filterValue.paramsID)) {
				keyToCheck = filterValue.paramsID[0];
			}
			if (keyToCheck==paramKey) {
				keyFilter = filterValue;
			} else if (filterValue.filters) {
				_.each(filterValue.filters, childFilterValue => {
					if (childFilterValue.paramsID==paramKey) {
						keyFilter = filterValue;
					}
				});
			} else if (keyToCheck==paramKey) {
				keyFilter = filterValue;
			}
		});
	});
	return keyFilter;
}

export const setJsonParam = (key, value, jsonParams) => {
	if (_.includes(key,'.')) {
		const paramKeys = _.split(key,'.');
		if (jsonParams[paramKeys[0]]) {
			jsonParams[paramKeys[0]][paramKeys[1]] = value;
		} else  {
			jsonParams[paramKeys[0]] = {};
			jsonParams[paramKeys[0]][paramKeys[1]] = value;
		}
	} else {
		if (key=='advanced_suitability_categories') {
			jsonParams[key] = _.map(value, (value, key) => {
				let categoryID = key;
				if (isNumeric(key)) categoryID = Number(key);
				return ({ category_id: categoryID, score_threshold: value })
			});
		} else {
			jsonParams[key] = value;
		}
	}
};

export const onMapOptions = (listParams, filterOptions, filter, flat=false) => {
	const { options, optionsID, optionsIDDependant } = filter;
	if (flat) {
		return filterOptions[`${optionsID}_flat`];
	} else if (optionsID&&optionsIDDependant) {
		if (optionsIDDependant=='segment_type') {
			const type = Segment.TYPES[listParams.segment_type] || 'none';
			return filterOptions[`${type}${optionsID}`];
		}
		return filterOptions[`${listParams[optionsIDDependant]}${optionsID}`];
	} else if (optionsID) {
		if (_.includes(optionsID, '.')) {
			const objectIDs = optionsID.split('.');
			return filterOptions[objectIDs[0]][objectIDs[1]];
		}
		return filterOptions[optionsID];
	}
	return options;
}

export const onPopulateDescription = (filter, descriptionType, listParams, toggleStates, filterOptions, stringified=true) => {
	const { filterType, paramsID, summaryMainFilter } = filter;
	let checkParamsID = paramsID;
	if (_.isArray(checkParamsID)) {
		checkParamsID = paramsID[0];
	}
	if (descriptionType=='count') {
		if (!_.isUndefined(summaryMainFilter)) {
			checkParamsID = summaryMainFilter;
		} else if (filterType=='compound-toggle') {
			checkParamsID = toggleStates[paramsID];
		}
		return listParams[checkParamsID]?.length > 0 ? listParams[checkParamsID].length : undefined;
	} else if (descriptionType=='input-number') {
		let value = listParams[paramsID];
		if (_.includes(value,', ')) {
			const splitted = _.split(value,', ');
			value = [];
			if (!_.isEmpty(splitted[0])) value.push(`Min ${thousandsSeparated(splitted[0])}`);
			if (!_.isEmpty(splitted[1])) value.push(`Max ${thousandsSeparated(splitted[1])}`);
			value = _.join(value, ' ');
		}
		return listParams[paramsID] ? value : undefined;
	} else if (descriptionType=='selections') {
		let filterToPopulate = filter;
		if (filterType=='compound-toggle') {
			checkParamsID = toggleStates[paramsID];
			filterToPopulate = _.find(filter.filters, ['toggleID', checkParamsID]);
		} else if (filterType=='compound-vertical') {
			checkParamsID = filter['summaryMainFilter'];
			filterToPopulate = _.find(filter.filters, ['paramsID', checkParamsID]);
		}
		if (!_.isEmpty(filterToPopulate) && checkParamsID!==filterToPopulate?.paramsID && !_.isArray(filterToPopulate?.paramsID)) {
			checkParamsID = filterToPopulate.paramsID;
		}
		const { optionsIDKey, optionsTitleKey, selectionsTooltipItems } = filterToPopulate;
		if (_.isArray(listParams[checkParamsID])) {
			if (selectionsTooltipItems=='as-is') {
				if (stringified) {
					let stringValue = listParams[checkParamsID].join(', ');
					if (stringValue.length>300) stringValue = `${stringValue.substring(0, 400)}...`;
					return stringValue;
				}
				return listParams[checkParamsID];
			} else if (selectionsTooltipItems=='optionsTitleKey') {
				if (stringified) {
					let stringValue = listParams[checkParamsID].map(item => item[optionsTitleKey]).join(', ');
					if (stringValue.length>300) stringValue = `${stringValue.substring(0, 400)}...`;
					return stringValue;
				}
			} else if (selectionsTooltipItems=='children-selections') {
				const options = _.flatten(_.map(onMapOptions(listParams, filterOptions, filterToPopulate), parent => {
					let returnValue = [ parent ];
					if (_.isArray(parent.children)) returnValue = [ ...returnValue, ...parent.children ];
					return returnValue;
				}));
				const mappedValues = _.map(listParams[checkParamsID], id => {
					const itemIndex = _.findIndex(options, [optionsIDKey, id]);
					const foundItem = options[itemIndex] || {};
					return foundItem[optionsTitleKey];
				});
				if (stringified) {
					let stringValue = mappedValues.join(', ');
					if (stringValue.length>300) stringValue = `${stringValue.substring(0, 400)}...`;
					return stringValue;
				}
				return mappedValues;
			}
			const mappedValues = _.map(listParams[checkParamsID], id => {
				const options = onMapOptions(listParams, filterOptions, filterToPopulate);
				const itemIndex = _.findIndex(options, [optionsIDKey, id]);
				const foundItem = options[itemIndex] || {};
				return foundItem[optionsTitleKey];
			});
			if (stringified) {
				let stringValue = mappedValues.join(', ');
				if (stringValue.length>300) stringValue = `${stringValue.substring(0, 400)}...`;
				return stringValue;
			}
			return mappedValues;
		} else if (_.isNumber(listParams[checkParamsID])||_.isString(listParams[checkParamsID])) {
			const options = onMapOptions(listParams, filterOptions, filterToPopulate);
			const itemIndex = _.findIndex(options, [optionsIDKey, listParams[checkParamsID]]);
			const foundItem = options[itemIndex] || {};
			return foundItem[optionsTitleKey];
		}
	} else if (descriptionType=='boolean') {
		return listParams[paramsID] ? 'Yes' : 'No Preference';
	} else if (descriptionType=='brand-suitability') {
		if (listParams.score_threshold) {
			return GlobalConst.brandSuitabilityOptions[listParams.score_threshold];
		} else if (listParams.advanced_suitability_categories) {
			return Object.keys(listParams.advanced_suitability_categories).length;
		}
	} else if (descriptionType=='brand-suitability-details') {
		let stringValue = _.map(mapGarmSuitability(listParams.advanced_suitability_categories, filterOptions.advanced_suitability_categories, true), item => item.title).join(', ');
		if (stringValue.length>300) stringValue = `${stringValue.substring(0, 400)}...`;
		return stringValue;
	} else if (descriptionType=='file-name') {
		return listParams[filter.paramsID]?.name;
	}
	return undefined;
}

export const mapGarmSuitability = (selections, options, rawType=false) => {
	const advancedSuitabilityStrings = { 1: 'Allow Any Content', 2: 'Allow Low Suitability Content', 3: 'Allow Medium Suitability Content', 4: 'Allow High Suitability Content' };
	if (rawType) {
		return _.map(selections, (value, key) => {
			const advancedCategory = _.find(options, ['id', Number(key)]);
			const suitability = advancedSuitabilityStrings[value];
			return ({ id: _.random(0,100), title: advancedCategory?.name || 'Not Available', description: suitability });
		});
	}
	return _.map(selections, item => {
		const advancedCategory = _.find(options, ['id', item.category_id]);
		const suitability = advancedSuitabilityStrings[item.score_threshold];
		return ({ id: _.random(0,100), title: advancedCategory?.name || 'Not Available', description: suitability });
	})
}

export const getFilterTitle = (filter, listParams, toggleStates) => {
	if (filter.filters) {
		let mainTitle = filter.title;
		_.each(filter.filters, item => {
			if (toggleStates[filter.paramsID]==item.paramsID&&item.secondaryTitle) {
				mainTitle = item.secondaryTitle;
			}
		});
		return mainTitle;
	}
	return filter.title;
}
