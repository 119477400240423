import numeral from 'numeral';
import GlobalConst from './GlobalConst';
import _ from 'lodash';

const oneTrillion = 1000000000000;
const oneBillion = 1000000000;
const oneMillion = 1000000;
const oneThousand = 1000;
const isInt = (n) => n % 1 === 0;

export function round(num, point) {
	const number = Number(num);
	if (!_.isNumber(num)) return '-';
	return isInt(number) ? number : Number(parseFloat(number.toFixed(point)));
}

export function roundMoney(num, point) {
	const number = Number(num);
	if (!_.isNumber(num)) return '-';
	return isInt(number) ? `$${number}` : `$${Number(parseFloat(number.toFixed(point)))}`;
}

export function roundAnStringify(num, point) {
	const minValue = 1.0 / Math.pow(10.0, point);
	return Math.abs(num) < minValue && num !== 0 ? `<${minValue}` : String(round(num, point));
}

export function formatMoney(num, roundDigits = 1) {
	if (!_.isNumber(num) && !_.isString(num)) return '-';
	return `$${formatBigNumber(num, roundDigits)}`
}

export function formatMoneyWithCode(num, roundDigits = 1, code) {
	return `${formatBigNumber(num, roundDigits)} ${code}`
}

export function formatMoneyWithCodeSymbol(num, roundDigits = 1, code, symbol) {
	return `${symbol}${formatBigNumber(num, roundDigits)} ${code}`
}

export function formatPercentWithSign(num, roundDigits = 1) {
	return `${num > 0 ? '+' : ''}${formatPercent(num, roundDigits)}`
}

export function formatPercent(num, roundDigits = 1, multiplier = 100.0) {
	if (!_.isNumber(num) && !_.isString(num)) return '-';
	return `${roundAnStringify(num * multiplier, roundDigits)}%`
}

export function formatBigPercent(num, roundDigits = 1, multiplier = 100.0) {
	if (!_.isNumber(num) && !_.isString(num)) return '-';
	return `${formatBigNumber(roundAnStringify(num * multiplier, roundDigits))}%`
}

export function format0to1Percent(num, roundDigits = 1) {
	return `${roundAnStringify(num, roundDigits)}%`
}

export function formatBigNumberWithSign(num, roundDigits = 1) {
	return `${num > 0 ? '+' : ''}${formatBigNumber(num, roundDigits)}`
}

export function formatBigNumber(number, roundDigits = 1) {
	if (!_.isNumber(number) && !_.isString(number)) return '-';
	const num = Number(number);
	if (Math.abs(num) >= oneTrillion) {
		return roundAnStringify(num / oneTrillion, roundDigits) + 'T';
	}
	if (Math.abs(num) >= oneBillion) {
		return roundAnStringify(num / oneBillion, roundDigits) + 'B';
	}
	if (Math.abs(num) >= oneMillion) {
		return roundAnStringify(num / oneMillion, roundDigits) + 'M';
	}
	if (Math.abs(num) >= oneThousand) {
		return roundAnStringify(num / oneThousand, roundDigits) + 'K';
	}
	return roundAnStringify(num, roundDigits);
}

export function thousandsSeparated(num, roundDigits=0) {
	const roundDigitsFormat = _.reduce(Array(roundDigits), (acc) => { return acc+'0'}, '');
	return numeral(num).format(`0,0.${roundDigitsFormat}[00000000]`);
}

export function formatterForChartType(chartType, roundDigits = 1, currency) {
	if (chartType == GlobalConst.CHART_TYPE_PERCENT) {
		return val => format0to1Percent(val, roundDigits);
	} else if (chartType == GlobalConst.CHART_TYPE_MONEY) {
		if (_.isString(currency) && currency !== 'USD') return val => formatMoneyWithCode(val, roundDigits, currency);
		if (_.isString(currency) && currency == 'USD') return val => formatMoneyWithCodeSymbol(val, roundDigits, currency, '$');
		return val => formatMoney(val, roundDigits);
	} else if (chartType == GlobalConst.CHART_TYPE_NUMBER_WITH_SIGN) {
		return val => formatBigNumberWithSign(val, roundDigits);
	}
	return val => formatBigNumber(val, roundDigits);
}

export function decimalPlacesCount(num) {
	var match = (''+num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
	if (!match) { return 0; }
	return Math.max(0, (match[1] ? match[1].length : 0) - (match[2] ? +match[2] : 0));
}

export function imageCompressionRatio(number) {
	const maxSize = 1024*1024*10; // 10MB
	if (number < maxSize) {
		return 1.0;
	}
	return (maxSize/number).toFixed(1);
}

export function formatNumberCurrency(number, callback, roundDigits=1) {
	if (_.isString(number)) {
		const splitted = _.split(number, ' ');
		if (!_.isEmpty(splitted[1])) return `${callback(splitted[0], roundDigits)} ${splitted[1]}`;
		return `${callback(splitted[0], roundDigits)}`;
	}
}
