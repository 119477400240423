import React, { Children } from 'react';
import PropTypes from 'prop-types';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import cx from 'classnames';
import _ from 'lodash';
import $ from 'jquery';
import { areComponentsEqual } from '../../utils/func';

export class ModalHeaderV2 extends ShallowRenderedComponent {

	render() {
		const { light } = this.props;
		return (
			<div className={cx('modal-header', light ? 'light' : '')}>
				{this.props.children}
			</div>
		);
	}

}

export class ModalFooterV2 extends ShallowRenderedComponent {

	render() {
		const { dark } = this.props;
		return (
			<div className={cx('modal-footer', dark ? 'dark' : '')}>
				{this.props.children}
			</div>
		);
	}

}

export class ModalV2 extends ShallowRenderedComponent {

	static SIZE = {
		XLARGE: 'modal-xl',
		LARGE: 'modal-lg',
		SMALL: 'modal-sm',
		NORMAL: ''
	};

	static propTypes = {
		size: PropTypes.string,
		noCloseOutside: PropTypes.bool,
		mountImmediately: PropTypes.bool,
		unmountOnHide: PropTypes.bool,
		onShow: PropTypes.func,
		onHide: PropTypes.func,
		noScroll: PropTypes.bool
	};

	static defaultProps = {
		size: ModalV2.SIZE.NORMAL,
		noCloseOutside: false,
		mountImmediately: false,
		unmountOnHide: false,
		noScroll: false
	};

	constructor(props) {
		super(props);
		this.state = {
			wasShown: false,
			...this.state
		};
		this.propsToOmitOnUpdate = ['onShow', 'onHide'];
	}

	componentDidMount() {
		super.componentDidMount();
		const $modal = $(`#${this.props.id}`);
		$modal.on('show.bs.modal', () => {
			this.setState({wasShown: true});
			if(this.props.onShow) {
				this.props.onShow();
			}
		});
		$modal.on('hidden.bs.modal', () => {
			if(this.props.unmountOnHide) {
				this.setState({wasShown: false});
				if(this.props.onHide) {
					this.props.onHide();
				}
			}
		});
	}

	componentWillUnmount() {
		const $modal = $(`#${this.props.id}`);
		$modal.modal('hide');
		$modal.off('show.bs.modal');
		$modal.off('hidden.bs.modal');
		super.componentWillUnmount();
	}

	render() {
		const { id, children, className, size, style, noCloseOutside, mountImmediately, noScroll } = this.props;
		const { wasShown } = this.state;
		const noCloseOutsideProps = noCloseOutside ? {'data-backdrop': 'static', 'data-keyboard': 'false'} : {};
		const childrenArray = Children.toArray(children);
		const childElements = _.filter(childrenArray, child => !areComponentsEqual(child.type,  ModalHeaderV2) && !areComponentsEqual(child.type, ModalFooterV2));
		const header = _.find(childrenArray, child => areComponentsEqual(child.type, ModalHeaderV2));
		const footer = _.find(childrenArray, child => areComponentsEqual(child.type, ModalFooterV2));
		return (
			<div className="modal-v2">
				<div className={cx('modal scale fade', className)} id={id} tabIndex={-1} role="dialog" aria-hidden="true" {...noCloseOutsideProps}>
					<div className={cx('modal-dialog', size)} style={style}>
						<div className="modal-content">
							{header}
							<div className="modal-body" style={{overflow: noScroll ? 'visible' : null}}>
								{(wasShown || mountImmediately) && childElements}
							</div>
							{footer}
						</div>
					</div>
				</div>
			</div>
		);
	}

}

export default ModalV2;
