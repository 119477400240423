import React, { Children } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import _ from 'lodash';
import GlobalConst from '../../utils/GlobalConst';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import $ from 'jquery';

class Input extends ShallowRenderedComponent {

	static propTypes = {
		pullRight: PropTypes.bool,
		maxMenuHeight: PropTypes.number,
		noClose: PropTypes.bool,
		prefix: PropTypes.string,
		multiselect: PropTypes.bool,
		type: PropTypes.string
	};

	static defaultProps = {
		pullRight: false,
		maxMenuHeight: 400,
		noClose: false,
		multiselect: false,
		type: 'text'
	};

	constructor(props) {
		super(props);
		this.state = {
			active: props.autoFocus,
			showPassword: false
		};
		this.propsToOmitOnUpdate = ['onChange'];
	}

	focus() {
		this.input && this.input.focus();
	}

	value() {
		return this.input ? this.input.value : '';
	}

	handleFocus = () => {
		const { onFocus } = this.props;
		this.setState({active: true});
		onFocus && onFocus();
	};

	handleBlur = event => {
		const { onBlur } = this.props;
		this.setState({active: false});
		if (onBlur) onBlur(event);
	};

	onKeyDown = event => {
		const { onEnter, onEscape, onDownKey, onUpKey } = this.props;
		if(event.which === GlobalConst.ENTER_KEY) {
			onEnter && onEnter();
		} else if(event.which === GlobalConst.ESCAPE_KEY) {
			onEscape && onEscape();
		} else if(event.which === GlobalConst.UPARROW_KEY) {
			onUpKey && onUpKey();
		} else if(event.which === GlobalConst.DOWNARROW_KEY) {
			onDownKey && onDownKey();
		}
	};

	isMenuOpened() {
		return $(this.container).hasClass('open');
	}

	toggleMenu() {
		$(this.wrapper).dropdown('toggle');
	}

	openMenu() {
		if(!this.isMenuOpened()) {
			this.toggleMenu();
		}
	}

	closeMenu() {
		if(this.isMenuOpened()) {
			this.toggleMenu();
		}
	}

	renderMenu() {
		const { children, pullRight, maxMenuHeight, noClose, multiselect, onSelect, isLoading } = this.props;
		if(!this.hasMenu()) {
			return null;
		}
		return (
			<ul className={cx('dropdown-menu', {'dropdown-menu-right': pullRight, noclose: noClose})}
				style={{maxHeight: maxMenuHeight ? maxMenuHeight + 'px' : null}} role="menu">
				{Children.map(children, (child, i) => {
					if(child) {
						const isSelected = _.includes(child.props.className, 'selected');
						const isHighlighted = _.includes(child.props.className, 'highlighted');
						const noDisplay = _.includes(child.props.className, 'hide-select');
						return (
							<li key={i} className={cx({'selected':isSelected, 'highlighted':isHighlighted, 'no-spacing': noDisplay, 'disabled-link': isLoading})} onClick={() => onSelect(child.props.item)}>
								{!noDisplay ? isSelected && multiselect ?
									<i className="material-icons">check_box</i> :
									<i className="material-icons">check_box_outline_blank</i> : null}
								{child}
							</li>
						);
					}
					return null;
				})}
			</ul>
		);
	}

	hasMenu() {
		const children = Children.toArray(this.props.children);
		return _.filter(children, child => Boolean(child)).length > 0;
	}

	resolveValue() {
		const { value, prefix } = this.props;
		if(_.isEmpty(prefix) || _.startsWith(value, prefix)) {
			return value;
		}
		return `${prefix}${value}`;
	}

	onChange = event => {
		const { onChange, prefix, id } = this.props;
		if(!_.isEmpty(prefix) && !_.startsWith(event.currentTarget.value, prefix)) return false;
		if (event == 'empty') {
			$(`#${id}`).focus();
			return onChange('');
		}
		return onChange(event.currentTarget.value);
	};

	render() {
		const { className, placeholder, value, autoFocus, error, disabled, readonly, id, showLabel, alternateDeleteSearch,
			style, helpBlockAbsolute, required, maxLength, min, max, step, whiteColor, noCross=true, showPasswordEye=false } = this.props;
		let { type } = this.props;
		const { active, showPassword } = this.state;
		const classes = cx('inputer', className, {'has-error': Boolean(error), 'floating-label': showLabel});
		if (type=='password'&&showPasswordEye) type = showPassword ? 'text' : 'password';
		return (
			<div className={classes} style={style} ref={node => this.container = node}>
				<div className={cx('input-wrapper', {active, disabled, readonly})} data-toggle={this.hasMenu() ? 'dropdown' : null} ref={node => this.wrapper = node}>
					<input id={id} type={type || 'text'} autoFocus={autoFocus} className={cx('form-control', {valid: !_.isEmpty(value)}, whiteColor ? 'white-color' : '')}
						   placeholder={showLabel ? '' : placeholder || ''} value={this.resolveValue()} onChange={this.onChange} required={required} maxLength={maxLength} min={min} max={max} step={step}
						   onFocus={this.handleFocus} onBlur={this.handleBlur} onKeyDown={this.onKeyDown} ref={node => this.input = node}/>
					{!showPasswordEye ? null : showPassword ? <i className='material-icons eye-pass no-select' onClick={() => this.setState({showPassword:false})}>visibility</i> :
						<i className='material-icons eye-pass no-select' onClick={() => this.setState({showPassword:true})}>visibility_off</i>}
					{noCross ? null :
						alternateDeleteSearch ?
						<span className='material-symbols-outlined' onClick={() => this.onChange('empty')}>close</span> :
						<i className="fa fa-times" aria-hidden="true" onClick={() => this.onChange('empty')}></i>}
					{showLabel && <label>{placeholder}</label>}
				</div>
				{this.renderMenu()}
				{error && <span className={cx('help-block', {absolute: helpBlockAbsolute})}><small>{error}</small></span>}
			</div>
		);
	}

}

export default Input;
