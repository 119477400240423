import React from 'react';
import PropTypes from 'prop-types';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import _ from 'lodash';

class BasicOptionsPicker extends ShallowRenderedComponent {

	static propTypes = {
		titleKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
		idKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
		descriptionKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
		iconKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
	};

	static defaultProps = {
		titleKey: 'title',
		idKey: 'id',
		descriptionKey: 'description',
		iconKey: 'icon'
	};

	getOptionTitle(option) {
		if(!option) {
			return null;
		}
		const { titleKey } = this.props;
		if(_.isFunction(titleKey)) {
			return titleKey(option);
		}
		return titleKey ? option[titleKey] || 'N/A' : null;
	}

	getOptionID(option) {
		if(!option) {
			return null;
		}
		const  { idKey } = this.props;
		if(_.isFunction(idKey)) {
			return idKey(option);
		}
		return idKey ? option[idKey] : null;
	}

	getOptionDescription(option) {
		if(!option) {
			return null;
		}
		const  { descriptionKey } = this.props;
		if(_.isFunction(descriptionKey)) {
			return descriptionKey(option);
		}
		return descriptionKey ? option[descriptionKey] : null;
	}
	
	getOptionIcon(option) {
		if (!option) {
			return null;
		}
		const  { iconKey } = this.props;
		if(_.isFunction(iconKey)) {
			return iconKey(option);
		}
		return iconKey ? <i className={`fa ${option[iconKey]} mr-5`}></i> : null;
	}
	
	getOptionImg(option) {
		if (!option) {
			return null;
		}
		const  { imgKey } = this.props;
		if(_.isFunction(imgKey)) {
			return imgKey(option);
		}
		return imgKey ? <div className='flex-centered' style={{ width: '24px' }}><img className='mr-5' src={option[imgKey]} /></div> : null;
	}

}

export default BasicOptionsPicker;
