import GlobalConst from '../utils/GlobalConst';
import PubSub from 'pubsub-js';

const withTrackChangedUser = Class => {
	return class extends Class {
		componentDidMount() {
			super.componentDidMount();
			this.userChangedSubscribe = PubSub.subscribe(GlobalConst.USER_CHANGED_MSG, () => {
				this.forceUpdate()
			});
		}

		componentWillUnmount() {
			PubSub.unsubscribe(this.userChangedSubscribe);
		}
	}
};

export default withTrackChangedUser;
