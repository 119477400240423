import _ from 'lodash';

export function validateEmail(email) {
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export function validateYouTubeURL(url) {
	if(_.isEmpty(url)) {
		return false;
	}
	return /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/(video\/|watch\?|embed\/)?.+/.test(url);
}

export function validateURL(url) {
	if (_.isEmpty(url)) {
		return false;
	}
	return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(url);
}

//can check URL in simple format (without protocol and with various query e.g. channelfactory.com)
export function validateShortURL(url) {
	if (_.isEmpty(url)) {
		return false;
	}
	return /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?/i.test(url);
}

export function httpURLToHttps(url) {
	return _.startsWith(url, 'http://') ? _.replace(url, 'http://', 'https://') : url;
}

export function parseFormError(error) {
	if(_.isObject(error)) {
		const result = {};
		_.each(error.json || error, (val, key) => {
			result[key] = _.isArray(val) ? val.join(', ') : val;
		});
		result.generalError = error.responseStatus == 400 ? result.non_field_errors : result.responseText || result.textStatus;
		return result;
	}
	return {generalError: error};
}

export function stringifyError(error) {
	if(_.isNil(error) || _.isFunction(error)) {
		return '';
	}
	if(_.isNumber(error)) {
		return String(error);
	}
	if(_.isString(error)) {
		return error;
	}
	if(_.isArray(error)) {
		return _.map(error, stringifyError).join(' ');
	}
	if(_.isObject(error)) {
		return stringifyError(_.values(error));
	}
	let result = 'Unknown error';
	try {
		result = JSON.stringify(error);
	} catch(err) {}
	return result;
}
