import React from 'react';

export default class GlobalConst {
	static SET_BREADCRUMBS_MSG = 'SET_BREADCRUMBS_MSG';
	static SHOW_MODAL_MSG = 'SHOW_MODAL_MSG';
	static USER_CHANGED_MSG = 'USER_CHANGED_MSG';
	static MODAL_TYPE_DEFAULT = 0;
	static MODAL_TYPE_SUCCESS = 1;
	static MODAL_TYPE_WARN = 2;
	static MODAL_TYPE_ERROR = 3;
	static MODAL_TYPE_CONTACT_US = 4;
	static ESCAPE_KEY = 27;
	static ENTER_KEY = 13;
	static UPARROW_KEY = 38;
	static DOWNARROW_KEY = 40;

	static SPOILER_EXPAND_MSG = 'SPOILER_EXPAND_MSG';
	static HIDE_EXPAND_CONTROL = 'HIDE_EXPAND_CONTROL';

	static CHART_TYPE_NUMBER = 1;
	static CHART_TYPE_PERCENT = 2;
	static CHART_TYPE_MONEY = 3;
	static CHART_TYPE_NUMBER_WITH_SIGN = 4;

	static CHART_GROUPING_BY_MONTHS = 1;
	static CHART_GROUPING_BY_DAYS = 2;
	static CHART_GROUPING_BY_HOURS = 3;

	static EXTERNAL_SOURCE_YOUTUBE = 'yt';
	static EXTERNAL_SOURCE_FACEBOOK = 'fb';
	static EXTERNAL_SOURCE_INFLUENCERS = 'influencers';
	static EXTERNAL_SOURCE_BRANDS = 'brands';
	static EXTERNAL_SOURCE_ENTERTAIMENT = 'media';
	static EXTERNAL_SOURCE_ALL = 'all';
	static EXTERNAL_SOURCE_NEW = 'new';

	static CATEGORY_YOUTUBE = 'youtube';
	static CATEGORY_CHF = 'chf';
	static CATEGORY_IAB = 'iab';
	static CATEGORY_PRIVATE = 'private';

	static EXTERNAL_SOURCE_VIRAL = 'viral';
	static EXTERNAL_SOURCE_TRENDING = 'trending';
	static EXTERNAL_SOURCE_MOST_WATCHED = 'most_watched';
	static EXTERNAL_SOURCE_MOST_LIKED = 'most_liked';

	static GENDER_COLORS = ['#A4A4A4', '#CC1919', '#363636'];
	static GENDER_ICONS = {'Male': 'fa-male', 'Female': 'fa-female', 'Gender_other': 'fa-question'};
	static DEVICES_COLORS = ['#BB9054', '#A4A4A4', '#CC1919', '#363636'];
	static DEVICES_ICONS = {
		'Computers': 'fa-desktop',
		'Mobile devices with full browsers': 'fa-mobile',
		'Tablets with full browsers': 'fa-tablet',
		'Other': 'fa-question'
	};
	static AGE_ALIAS = {
		'65 or more': '65+',
		'Undetermined': '?'
	};
	static MIME_TYPES_CSV = [
		'text/csv',
		'text/plain',
		'application/csv',
		'text/comma-separated-values',
		'application/excel',
		'application/vnd.ms-excel',
		'application/vnd.msexcel',
		'text/anytext',
		'application/octet-stream',
		'application/txt'
	];
	static SHOW_IAS = false;

	static prodRegionTemplateIDs = {
		1: 23083,
		2: 22886,
		3: 22357,
		4: 22852,
		5: 22885
	}

	static devRegionTemplateIDs = {
		1: 8101,
		2: 8067,
		3: 8034,
		4: 8035,
		5: 8001
	}
	static brandSuitabilityOptions = {
		1: 'Allow Any Content',
		2: 'Allow Low Suitability Content',
		3: 'Allow Medium Suitability Content',
		4: 'Allow Only Suitable Content'
	}
	static iconOptions = {
		1: {class: 'green', icon: <span className='material-symbols-outlined icon'>check_circle</span>},
		2: {class: 'info', icon: <span className='material-symbols-outlined icon'>info</span>},
		3: {class: 'warning', icon: <span className='material-symbols-outlined icon'>warning</span>}
	}
}
