import React from 'react';
import _ from 'lodash';
import cx from 'classnames';
import PubSub from 'pubsub-js';
import { format } from 'timeago.js';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import NotificationsDataProvider from './NotificationsManagerDataProvider';
import GlobalConst from '../../utils/GlobalConst';

class NotificationsMenu extends ShallowRenderedComponent {

	constructor(props) {
		super(props);
		this.state = {
			hasUnreadNotifications: false,
			loadingNotifications: false,
			notifications: [],
			updatingNotification: false
		};
	}

	componentDidMount() {
		this.fetchUserNotifications(false);
        this.intervalId = setInterval(() => this.fetchUserNotifications(true), 120000);
		PubSub.subscribe('READ_NOTIFICATION', (_msg, {id}) => this.onReadNotification(id));
    }

    componentWillUnmount() {
		clearInterval(this.intervalId);
	}

	fetchUserNotifications = (autoRefresh=false) => {
		if (autoRefresh) this.setState({ loadingNotifications: true });
		this.trackPromise(NotificationsDataProvider.getUserNotifications())
			.then(data => this.setState({ notifications: data, loadingNotifications: false }, () => this.onUpdateStatusDot()));
	}

	onReadNotification = (id) => {
		const { updatingNotification } = this.state;
		const { onNotificationToShow } = this.props;
		if (updatingNotification) return;
		this.setState({ updatingNotification: true });
		this.trackPromise(NotificationsDataProvider.onReadNotification(id))
			.then(() => {
				const { notifications } = this.state;
                const notificationIndex = _.findIndex(notifications, ['id',id]);
                const updatedNotifications = [ ...notifications ];
                updatedNotifications[notificationIndex].isRead = true;
                this.setState({ notifications: updatedNotifications, updatingNotification: false }, () => this.onUpdateStatusDot());
			})
			.catch(error => {
				// eslint-disable-next-line
				console.log('Error reading notification =>', error);
				this.setState({ updatingNotification: false });
				onNotificationToShow(null);
			});
	}

	onUpdateStatusDot = () => {
        const { notifications } = this.state;
		const { onNotificationToShow } = this.props;
        const nonReadNotifications = _.reduce(notifications, (acc, item) => {
            if (!item.isRead) acc.push(item);
            return acc;
        }, []);
        this.setState({ hasUnreadNotifications: nonReadNotifications.length>0 }, () => onNotificationToShow(nonReadNotifications[0]));
	}

	onToggleNotifications = () => {
		this.props.onToggleNotifications();
	}

	render() {
		const { hasUnreadNotifications, loadingNotifications, notifications } = this.state;
		const { showNotificationsContainer } = this.props;
		return <div id='notifications-menu' className='notifications-menu'>
			<div className='notifications-toggle'>
				<span className='material-symbols-outlined' onClick={this.onToggleNotifications}>notifications</span>
				<div className={cx('dot',{'show':hasUnreadNotifications})}/>
			</div>
			<div className={cx('notifications-container',{'visible':showNotificationsContainer})}>
				<div className='header'>Notifications</div>
				{_.isEmpty(notifications) ?
					<div className='empty-notifications-message'>
						{loadingNotifications ? 'Loading notifications...' : 'You don\'t have notifications'}
					</div> :
					_.map(notifications, item => <div className={cx('notification-item',{'new':!item.isRead})} onClick={() => this.onReadNotification(item.id)}>
						<div className='read-dot'/>
						{GlobalConst.iconOptions[item.type]?.icon}
                        <div className='message-container'>
                            <span className='message'>
                                <span className='description'>{item.message}</span>
                                {item.link && <a href={item.link} target='_blank'>{item.linkText}</a>}
                            </span>
                            <span className='time'>{format(item.updatedAt)}</span>
                        </div>
					</div>)}
			</div>
		</div>;
	}
}

export default NotificationsMenu;
