import AuthDataProvider from '../components/auth/AuthDataProvider';
import WhiteLabelSiteModel from '../models/WhiteLabelSiteModel';

class WhiteLabelController {

    siteAssets = WhiteLabelSiteModel.fromJSON({ config: {} });

    getLocalAssets() {
        const siteAssets = sessionStorage.getItem(`${window.location.hostname}_site_assets`)
        if (sessionStorage.getItem(`${window.location.hostname}_site_assets`)) {
            this.siteAssets = JSON.parse(siteAssets);
            return Promise.resolve(this.siteAssets);
        }
        return AuthDataProvider.getSiteAssets().then(assets => this.setLocalAssets(assets));
    }

    setLocalAssets(assets) {
        sessionStorage.setItem(`${window.location.hostname}_site_assets`, JSON.stringify(assets));
        this.siteAssets = assets;
        return assets;
    }

}

const assetsController = new WhiteLabelController();
export default assetsController;
