export default class WhiteLabelSite {
	static fromJSON(json) {
		const { logo, name, disable, favicon, suitability_name } = json.config;
		return {
			id: json.id,
			domain: json.domain,
			logo: logo,
			name: name,
			favicon: favicon,
			suitabilityName: suitability_name || 'CF Suitability',
			restricted: disable || []
		};
	}
}
