import React from 'react';
import Button from '../markup/Button';

const GDPRCookiesBox = props => {
    const { onGDPRCookiesAction } = props;
    return <div className='gdprcookiesbox'>
        <div className='text-area'>
            <p>This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. We use this information
                in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use,
                see our Privacy Policy.  <a href="https://channelfactory.com/privacy-policy/" target="_blank">Read More</a></p>
            <p>If you decline, your information won't be tracked when you visit this website. A single cookie will
                be used in your browser to remember your preference not to be tracked.</p>
            <Button className='button-black-1-small mr-10' onClick={() => onGDPRCookiesAction(true)}>Accept</Button>
            <Button className='button-grey-1-small' onClick={() => onGDPRCookiesAction(false)}>Decline</Button>
        </div>
    </div>;
}

export default GDPRCookiesBox;
