import React from 'react';
import $ from 'jquery';
import _ from 'lodash';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import GoogleAdsLogo from '../../img/gads-justlogo.png';
import DV360Logo from '../../img/dv360-justlogo.png';
import AuthDataProvider from '../auth/AuthDataProvider';
import UserManager from '../../core/UserManager';
import PubSub from 'pubsub-js';
import OAuthSyncConfirmModal from './OAuthSyncConfirmModal';

export default class OAuthSyncContainer extends ShallowRenderedComponent {

    static propTypes = {
        globalOAuthData: PropTypes.object,
        showDV360: PropTypes.bool,
        showGAds: PropTypes.bool,
        noStatus: PropTypes.bool
    };

    static defaultProps = {
        globalOAuthData: {},
        showGAds: true,
        showDV360: true
    }

    constructor(props) {
        super(props);
        this.state = {
            unlinkingDVAccount: false,
            unlinkingGAAccount: false,
            itemHovered: null,
            googleAdsLoading: false,
            dv360Loading: false,
            syncConfirmModalOpen: false,
            isUnlinking: false,
            impersonationSyncOverride: false
        }
    }

    postGoogleOAuthCode = () => {
        const { globalOAuthData: { fetchUserData } } = this.props;
        if (this.checkSyncRequiresConfirm('ga')) return;
        return UserManager.authByGoogleAdWords()
            .then(res => {
                this.setState({googleAdsLoading: true});
                return this.trackPromise(AuthDataProvider.getGoogleAdsAccounts(res))
                    .then(() => fetchUserData())
                    .catch(error => this.showErrorModal('Error getting Google Ads account data', error));
            })
            .catch(err => {
                if (err == 'idpiframe_initialization_failed') {
                    return this.showErrorModal('Private browser error', 'Please enable cookies on your browser.');
                }
                return this.showErrorModal('Error while loading account data', err);
            })
            .finally(() => this.setState({googleAdsLoading: false}));
    }

    postDV360OAuthCode = () => {
        const { globalOAuthData: { fetchUserData } } = this.props;
        if (this.checkSyncRequiresConfirm('dv')) return;
        return UserManager.authByDV360()
            .then(res => {
                this.setState({dv360Loading: true});
                return this.trackPromise(AuthDataProvider.getDV360Accounts(res))
                    .then(() => fetchUserData())
                    .catch(error => this.showErrorModal('Error getting DV360 account data', error));
            })
            .catch(err => {
                if (err == 'idpiframe_initialization_failed') {
                    return this.showErrorModal('Private browser error', 'Please enable cookies on your browser.');
                }
                return this.showErrorModal('Error while loading account data', err);
            })
            .finally(() => this.setState({dv360Loading: false}));
    }

    unlinkAccount = (type) => {
        const { globalOAuthData: { googleAdsUserData, dv360UserData } } = this.props;
        const accountID = type == 'ga' ? googleAdsUserData?.id : dv360UserData?.id;
        if (this.checkSyncRequiresConfirm(type, true)) return;
        if (type == 'ga') this.setState({ unlinkingGAAccount: true });
        else this.setState({ unlinkingDVAccount: true });
        return this.trackPromise(AuthDataProvider.unlinkAccount(accountID))
            .then(() => PubSub.publish(type=='ga'?'CLEAR_GADS_OAUTH':'CLEAR_DV360_OAUTH'))
            .catch(error => this.showErrorModal('Error unsyncing account', error))
            .finally(() => {
                if (type == 'ga') this.setState({ unlinkingGAAccount: false, isUnlinking: false });
                else this.setState({ unlinkingDVAccount: false, isUnlinking: false });
            });
    }

    checkSyncRequiresConfirm = (syncType, isUnlinking=false) => {
        const { impersonationSyncOverride } = this.state;
		const currentAuthToken = localStorage.getItem('auth_token');
		const originalAuthToken = localStorage.getItem('auth_token_original_user');
        const isImpersonating = !_.isEmpty(originalAuthToken) && !(currentAuthToken === originalAuthToken);
        if (isImpersonating && !impersonationSyncOverride) {
            this.setState({syncConfirmModalOpen: syncType, isUnlinking: isUnlinking}, () => $('#sync-confirm-modal').modal('show'));
            return true;
        }
        this.setState({impersonationSyncOverride: false});
    }

    onConfirmSync = (confirmed) => {
        this.setState({ syncConfirmModalOpen: false }, () => $('sync-confirm-modal').modal('hide'));
        if (!confirmed) return;
		const { syncConfirmModalOpen, isUnlinking } = this.state;
        let syncCallback;
        if (isUnlinking) syncCallback = () => this.unlinkAccount(syncConfirmModalOpen);
        else {
            if (syncConfirmModalOpen == 'ga') syncCallback = this.postGoogleOAuthCode;
            if (syncConfirmModalOpen == 'dv') syncCallback = this.postDV360OAuthCode;
        }
        this.setState({ impersonationSyncOverride: true }, syncCallback);
    }

    render() {
        const { globalOAuthData: { loadingUserData, googleAdsUserData, dv360UserData }, showGAds, showDV360, noStatus } = this.props;
        const { unlinkingGAAccount, unlinkingDVAccount, itemHovered, googleAdsLoading, dv360Loading, syncConfirmModalOpen, isUnlinking} = this.state;
        return <div className='oauth-container'>
            {showGAds && <div className='item mb-10 mr-0' onMouseEnter={()=>this.setState({itemHovered:'ga'})} onMouseLeave={()=>this.setState({itemHovered:null})}>
                <div className={cx('img-container google', {'not-active':_.isEmpty(googleAdsUserData),'syncing':!_.isEmpty(googleAdsUserData)&&!googleAdsUserData?.synced})}>
                    <img className='asset' src={GoogleAdsLogo}/>
                </div>
                {_.isEmpty(googleAdsUserData) || noStatus  ? null :
                    googleAdsUserData?.synced ? <div className='synced dot'><i className='fa fa-check'/></div> :
                    <div className='syncing dot'><span className='material-icons'>autorenew</span></div>}
                <div className={cx('email-container google',{'not-active':_.isEmpty(googleAdsUserData)})}>
                    {_.isEmpty(googleAdsUserData) ?
                        <button className='link-btn'><span>{loadingUserData||googleAdsLoading?'Loading...':'Google Ads'}</span></button> :
                        <span className='email'>{loadingUserData||googleAdsLoading?'Loading...':'Google Ads'}</span>}
                    <div className='sync'>
                        {_.isEmpty(googleAdsUserData) ? null : googleAdsUserData?.synced ?
                            <span className='synced'>sync'd<span className='material-icons ml-3'>cloud_done</span></span>
                            : <span className='syncing'>syncing<span className='material-icons'>autorenew</span></span>}
                    </div>
                    {itemHovered=='ga' && !noStatus && <span className='unlink' onClick={() => {
                        if (_.isEmpty(googleAdsUserData)) this.postGoogleOAuthCode();
                        else this.unlinkAccount('ga')}
                    }>
                        {_.isEmpty(googleAdsUserData) ? 'Sync' : unlinkingGAAccount?'Unsyncing':'Unsync'}
                    </span>}
                </div>
            </div>}
            {showDV360 && <div className='item' onMouseEnter={()=>this.setState({itemHovered:'dv'})} onMouseLeave={()=>this.setState({itemHovered:null})}>
                <div className={cx('img-container dv360',{'not-active':_.isEmpty(dv360UserData),'syncing':!_.isEmpty(dv360UserData)&&!dv360UserData?.synced})}>
                    <img className='asset' src={DV360Logo}/>
                </div>
                {_.isEmpty(dv360UserData) || noStatus ? null :
                    dv360UserData?.synced ? <div className='synced dot'><i className='fa fa-check'/></div> :
                        <div className='syncing dot'><span className='material-icons'>autorenew</span></div>}
                <div className={cx('email-container dv360',{'not-active':_.isEmpty(dv360UserData)})}>
                    {_.isEmpty(dv360UserData) ?
                        <button className='link-btn'><span>{loadingUserData||dv360Loading?'Loading...':'DV360'}</span></button> :
                        <span className='email'>{loadingUserData||dv360Loading?'Loading...':'DV360'}</span>}
                    <div className='sync'>
                        {_.isEmpty(dv360UserData) ? null : dv360UserData?.synced ?
                            <span className='synced'>sync'd<span className='material-icons ml-3'>cloud_done</span></span>
                            : <span className='syncing'>syncing<span className='material-icons'>autorenew</span></span>}
                    </div>
                    {itemHovered=='dv' && !noStatus && <span className='unlink' onClick={() => {
                        if (_.isEmpty(dv360UserData)) this.postDV360OAuthCode();
                        else this.unlinkAccount('dv')
                    }}>
                        {_.isEmpty(dv360UserData) ? 'Sync' : unlinkingDVAccount?'Unsyncing':'Unsync'}
                    </span>}
                </div>
            </div>}
            {syncConfirmModalOpen && <OAuthSyncConfirmModal onCloseModal={(confirmed) => this.onConfirmSync(confirmed)} isUnlinking={isUnlinking} />}
        </div>;
    }

}
