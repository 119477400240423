import React, { Children } from 'react';
import PropTypes from 'prop-types';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import cx from 'classnames';
import _ from 'lodash';
import $ from 'jquery';
import { areComponentsEqual } from '../../utils/func';

export class ModalHeader extends ShallowRenderedComponent {

	render() {
		return (
			<div className="modal-header">
				{this.props.children}
			</div>
		);
	}

}

export class ModalFooter extends ShallowRenderedComponent {

	render() {
		return (
			<div className="modal-footer">
				{this.props.children}
			</div>
		);
	}

}

export class Modal extends ShallowRenderedComponent {

	static SIZE = {
		LARGE: 'modal-lg',
		SMALL: 'modal-sm',
		NORMAL: ''
	};

	static propTypes = {
		size: PropTypes.string,
		noCloseOutside: PropTypes.bool,
		mountImmediately: PropTypes.bool,
		unmountOnHide: PropTypes.bool,
		onShow: PropTypes.func,
		onHide: PropTypes.func,
		noScroll: PropTypes.bool
	};

	static defaultProps = {
		size: Modal.SIZE.NORMAL,
		noCloseOutside: false,
		mountImmediately: false,
		unmountOnHide: false,
		noScroll: false
	};

	constructor(props) {
		super(props);
		this.state = {
			wasShown: false,
			...this.state
		};
		this.propsToOmitOnUpdate = ['onShow', 'onHide'];
	}

	componentDidMount() {
		super.componentDidMount();
		const $modal = $(`#${this.props.id}`);
		$modal.on('show.bs.modal', () => {
			this.setState({wasShown: true});
			if(this.props.onShow) {
				this.props.onShow();
			}
		});
		$modal.on('hidden.bs.modal', () => {
			if(this.props.unmountOnHide) {
				this.setState({wasShown: false});
				if(this.props.onHide) {
					this.props.onHide();
				}
			}
		});
	}

	componentWillUnmount() {
		const $modal = $(`#${this.props.id}`);
		$modal.modal('hide');
		$modal.off('show.bs.modal');
		$modal.off('hidden.bs.modal');
		super.componentWillUnmount();
	}

	render() {
		const { id, children, className, size, style, noCloseOutside, mountImmediately, noScroll } = this.props;
		const { wasShown } = this.state;
		const noCloseOutsideProps = noCloseOutside ? {'data-backdrop': 'static', 'data-keyboard': 'false'} : {};
		const childrenArray = Children.toArray(children);
		const childElements = _.filter(childrenArray, child => !areComponentsEqual(child.type,  ModalHeader) && !areComponentsEqual(child.type, ModalFooter));
		const header = _.find(childrenArray, child => areComponentsEqual(child.type, ModalHeader));
		const footer = _.find(childrenArray, child => areComponentsEqual(child.type, ModalFooter));
		return (
			<div className={cx('modal scale fade', className)} id={id} tabIndex={-1} role="dialog" aria-hidden="true" {...noCloseOutsideProps}>
				<div className={cx('modal-dialog', size)} style={style}>
					<div className="modal-content">
						{header}
						<div className="modal-body" style={{overflow: noScroll ? 'visible' : null}}>
							{(wasShown || mountImmediately) && childElements}
						</div>
						{footer}
					</div>
				</div>
			</div>
		);
	}

}

export default Modal;
