import _ from 'lodash';
import { normalizeLocalDataWithValueKey } from '../core/chartsDataMappers';
import dateUtil from '../utils/dates';
import { formatBigNumber } from '../utils/numbers';
import { httpURLToHttps } from '../utils/forms';

export class AWAccountInfo {

	static fromJSON(json){
		return _.extend(new this(), {
			id: json.id,
			name: json.name,
			isOngoing: json.is_ongoing,
			channelsCount: json.channels_count,
			videosCount: json.videos_count,
			startDate: dateUtil.dateWithoutZone(json.start_date),
			endDate: dateUtil.dateWithoutZone(json.end_date),
			campaigns: json.campaigns ? _.map(json.campaigns, AWAccountCampaign.fromJSON.bind(AWAccountCampaign)) : []
		});
	}

}

export class AWAccountCampaign {

	static fromJSON(json){
		return _.extend(new this(), {
			id: json.id,
			name: json.name,
			status: json.status,
			startDate: dateUtil.dateWithoutZone(json.start_date),
			endDate: dateUtil.dateWithoutZone(json.end_date)
		});
	}

}

export class AWCampaign {

	static fromJSON(json){
		return _.extend(new this(), {
			adGroups: json.ad_groups,
			endDate: dateUtil.dateWithoutZone(json.end_date),
			id: json.id,
			campaignCreationID: json.campaign_creation_id,
			name: json.name,
			startDate: dateUtil.dateWithoutZone(json.start_date),
			status: json.status
		});
	}

}

export class AWAccountOverviewData {

	static fromJSON(json){
		const overviewChartData = data => _.map(data, ({ name, value }) => ({title: name, value}));

		return _.extend(new this(), {
			clicksThisWeek: json.clicks_this_week,
			averageCpvBottom: json.average_cpv_bottom,
			videoViewRate: json.video_view_rate,
			video75rate: json.video75rate,
			videoViewsLastWeek: json.video_views_last_week,
			video25rate: json.video25rate,
			videoViewRateBottom: json.video_view_rate_bottom,
			impressionsLastWeek: json.impressions_last_week,
			viewThrough: json.view_through,
			clicks: json.clicks,
			ctrV: json.ctr_v,
			ctrVTop: json.ctr_v_top,
			cost: json.cost,
			impressionsThisWeek: json.impressions_this_week,
			location: overviewChartData(json.location),
			averageCpv: json.average_cpv,
			videoViews: json.video_views,
			costLastWeek: json.cost_last_week,
			video100rate: json.video100rate,
			video50rate: json.video50rate,
			clicksLastWeek: json.clicks_last_week,
			videoViewRateTop: json.video_view_rate_top,
			costThisWeek: json.cost_this_week,
			ctrTop: json.ctr_top,
			allConversions: json.all_conversions,
			ctrBottom: json.ctr_bottom,
			conversions: json.conversions,
			gender: overviewChartData(json.gender),
			device: overviewChartData(json.device),
			averageCpm: json.average_cpm,
			age: overviewChartData(json.age),
			ctrVBottom: json.ctr_v_bottom,
			ctr: json.ctr,
			averageCpvTop: json.average_cpv_top,
			impressions: json.impressions,
			videoViewsThisWeek: json.video_views_this_week,
			deliveredCost: json.delivered_cost,
			planCost: json.plan_cost,
			deliveredImpressions: json.delivered_impressions,
			planImpressions: json.plan_impressions,
			deliveredVideoViews: json.delivered_video_views,
			planVideoViews: json.plan_video_views,
			hasStatistics: json.has_statistics
		})
	}
}

export class AWReportData {

	static fromJSON(json){
		const isSummary = _.isUndefined(json.name);
		return _.extend(new this(), {
			averagePosition: json.average_position,
			id: json.id,
			ad: json.ad,
			keyword: json.keyword_id,
			averageCPM: json.average_cpm,
			averageCPV: json.average_cpv,
			clicks: json.clicks,
			cost: json.cost,
			ctr: json.ctr,
			ctrV: json.ctr_v,
			conversions: json.conversions,
			viewThrough: json.view_through,
			impressions: json.impressions,
			title: isSummary ? 'Total' : json.name,
			video100rate: json.video100rate,
			video25rate: json.video25rate,
			video50rate: json.video50rate,
			video75rate: json.video75rate,
			videoViewRate: json.video_view_rate,
			videoViews: json.video_views,
			duration: json.duration,
			thumbnail: json.thumbnail,
			isSummary: isSummary,
			ctaClicks: AWCtaClicks.fromJSON(json)
		});
	}

	hasCompletion() {
		return _.isNumber(this.video25rate || this.video50rate || this.video75rate || this.video100rate);
	}

	get name() {
		return this.ad ? `${this.title} - ${this.ad}` : this.title;
	}

}

export class AWAccountChartData {

	static fromJSON(json){
		return _.extend(new this(), {
			hasAdditionalChart: json.additional_chart,
			additionalChartType: json.additional_chart_type,
			title: json.title,
			data: _.map(json.data, r => {
				return {
					title: r.label,
					data: normalizeLocalDataWithValueKey(r.trend, 'value', 'label'),
					value: r.value,
					average: r.average,
					description: r.views && `${formatBigNumber(r.views)} views`
				}
			})
		});
	}

}

export class AWAccountLocation {

	static fromJSON(json){
		const res = _.extend(new this(), {
			id: json.geo_target ? json.geo_target.id : json.id,
			name: json.geo_target ? json.geo_target.name : json.name,
			lat: json.latitude || json.lat,
			lng: json.longitude || json.lng,
			radius: json.radius
		});
		if(!res.id) {
			res.id = this.generateLocationTitle(res.lat, res.lng, res.radius);
		}
		if(!res.name) {
			res.name = this.generateLocationTitle(res.lat, res.lng, res.radius);
		}
		return res;
	}

	static generateLocationTitle(lat, lng, radius) {
		let title = 'Unknown location';
		if(lat && lng) {
			title = `Lat: ${Number(lat).toFixed(2)} Lng: ${Number(lng).toFixed(2)}`;
			if(radius) {
				title += ` Radius: ${radius}km`;
			}
		}
		return title;
	}

}


export class AWAccountAnalyzeDetails {

	static fromJSON(json) {
		const deliveryTrendChartData = (data, key) => {
			const record = _.find(data, d => d.label === key);
			if(!record) {
				return [];
			}
			return normalizeLocalDataWithValueKey(record.trend, 'value', 'label');
		};

		return _.extend(new this(), {
			id: json.id,
			weeklyChart: normalizeLocalDataWithValueKey(json.weekly_chart, 'value', 'label'),
			accountCreation: json.account_creation,
			averageCpm: json.average_cpm,
			averageCpv: json.average_cpv,
			videoViewRate: json.video_view_rate,
			cost: json.cost,
			thumbnail: json.thumbnail,
			isManaged: json.is_managed,
			isEditable: json.is_editable,
			isDisapproved: json.is_disapproved,
			fromAW: json.from_aw,
			account: json.account,
			start: dateUtil.dateWithoutZone(json.start),
			status: json.status,
			name: json.name,
			end: dateUtil.dateWithoutZone(json.end),
			statisticMinDate: dateUtil.dateWithoutZone(json.statistic_min_date),
			statisticMaxDate: dateUtil.dateWithoutZone(json.statistic_max_date),
			videoViews: json.video_views,
			ctr: json.ctr,
			ctrV: json.ctr_v,
			clicks: json.clicks,
			details: json.details ? {
				adNetwork: json.details.ad_network,
				creative: json.details.creative,
				video50rate: json.details.video50rate,
				age: json.details.age,
				video75rate: json.details.video75rate,
				averagePosition: json.details.average_position,
				device: json.details.device,
				allConversions: json.details.all_conversions,
				conversions: json.details.conversions,
				gender: json.details.gender,
				deliveryTrend: {
					impressions: deliveryTrendChartData(json.details.delivery_trend, 'Impressions'),
					videoViews: deliveryTrendChartData(json.details.delivery_trend, 'Views')
				},
				viewThrough: json.details.view_through,
				video25rate: json.details.video25rate,
				video100rate: json.details.video100rate
			} : {},
			ctaClicks: AWCtaClicks.fromJSON(json),
			impressions: json.impressions,
			isChanged: json.is_changed,
			interestCount: json.interest_count,
			channelsCount: json.channel_count,
			videosCount: json.video_count,
			adCount: json.ad_count,
			keywordCount: json.keyword_count,
			topicCount: json.topic_count,
			updatedAt: dateUtil.dateWithoutZone(json.updated_at),
			sfAccount: json.sf_account,
			brand: json.brand,
			costMethod: json.cost_method,
			currency: json.currency_code
		});
	}

}

export class AWAccount {

	static DISAPPROVED_AD_MESSAGE = `One or more of your ads doesn't follow Google policies, but if you fix the problem, it may be able to run.<br>
		Reason: Pull from Google Ads you can edit the ad and resubmit the new ad for further approval.<br>
		Keep in mind that repeated disapproval could cause your Google Ads account to be suspended, so make sure to read our policies carefully.`;

	static fromJSON(json){
		return _.extend(new this(), {
			campaigns: _.map(json.campaign_creations, OptimizationAWAccountCampaign.fromJSON.bind(OptimizationAWAccountCampaign)),
			id: json.id,
			name: json.name,
			isApproved: json.is_approved,
			isEnded: json.is_ended,
			isPaused: json.is_paused,
			updatedAt: dateUtil.dateToLocalZone(json.updated_at),
			syncAt: dateUtil.dateToLocalZone(json.sync_at),
			account: json.account,
			MCCAccountID: json.mcc_account_id
		});
	}

	static toJSON(data) {
		return _.transform(data, (acc, value, key) => {
			switch(key) {
				case 'name':
					acc['name'] = value;
					break;
				case 'isApproved':
					if(Boolean(value)) {
						acc['is_approved'] = true;
					}
					break;
				case 'MCCAccountID':
					if(!_.isNil(value)) {
						acc['mcc_account_id'] = value;
					}
			}
		}, {});
	}

	getAdGroups() {
		return _.reduce(this.campaigns, (acc, campaign) => acc.concat(campaign.adGroups), []);
	}

	getAds() {
		return _.reduce(this.getAdGroups(), (acc, adGroup) => acc.concat(adGroup.ads), []);
	}

	campaignForAdGroupID(adGroupID) {
		return _.find(this.campaigns, campaign => _.some(campaign.adGroups, v => v.id == adGroupID));
	}

	adGroupForAdID(adID) {
		return _.find(this.getAdGroups(), adGroup => _.some(adGroup.ads, v => v.id == adID));
	}

}

export class OptimizationAWAccountCampaign {

	static fromJSON(json){
		return _.extend(new this(), {
			id: json.id,
			updatedAt: dateUtil.dateToLocalZone(json.updated_at),
			name: json.name,
			biddingStrategy: json.bid_strategy_type,
			budget: json.budget,
			devices: json.devices,
			start: dateUtil.dateWithoutZone(json.start),
			end: dateUtil.dateWithoutZone(json.end),
			frequencyCapping: _.map(json.frequency_capping, ({ level, time_unit, event_type, limit }) => ({level: level.id, limit, timeUnit: time_unit.id, eventType: event_type.id})),
			locations: _.map(json.location_rules, AWAccountLocation.fromJSON.bind(AWAccountLocation)),
			languages: json.languages,
			adSchedule: json.ad_schedule_rules,
			adGroups: _.map(json.ad_group_creations, OptimizationAWAccountCampaignAdGroup.fromJSON.bind(OptimizationAWAccountCampaignAdGroup)),
			contentExclusions: json.content_exclusions,
			deliveryMethod: json.delivery_method,
			videoAdFormat: json.video_ad_format,
			videoNetworks: json.video_networks,
			targetCPA: json.target_cpa,
			type: json.type
		});
	}

	static toJSON(data) {
		const prepareCoordinate = coordinate => Number(String(coordinate).substring(0, 10));
		return _.transform(data, (acc, value, key) => {
			switch(key) {
				case 'name':
					acc['name'] = value;
					break;
				case 'end':
					acc['end'] = value ? dateUtil.formatToLocalBackendFormat(value) : null;
					break;
				case 'start':
					acc['start'] = value ? dateUtil.formatToLocalBackendFormat(value) : null;
					break;
				case 'budget':
					acc['budget'] = Number(value);
					break;
				case 'targetCPA':
					acc['target_cpa'] = Number(value);
					break;
				case 'biddingStrategy':
					acc['bid_strategy_type'] = value.id;
					break;
				case 'type':
					acc['type'] = value.id;
					break;
				case 'devices':
					acc['devices'] = _.map(value, 'id');
					break;
				case 'languages':
					acc['languages'] = _.map(value, 'id');
					break;
				case 'videoNetworks':
					acc['video_networks'] = _.map(value, 'id');
					break;
				case 'contentExclusions':
					acc['content_exclusions'] = _.map(value, 'id');
					break;
				case 'adSchedule':
					acc['ad_schedule_rules'] = _.map(value, ({ id, day, from_hour, from_minute, to_hour, to_minute }) => {
							return {
								id: id,
								day: day,
								from_hour: from_hour,
								from_minute: from_minute,
								to_hour: to_hour,
								to_minute: to_minute
							};
						});
					break;
				case 'frequencyCapping':
					acc['frequency_capping'] = _.map(value, ({ eventType, level, limit, timeUnit }) => ({
						event_type: eventType,
						level: level,
						limit: Number(limit),
						time_unit: timeUnit
					}));
					break;
				case 'locations':
					acc['location_rules'] = _.map(value, v => {
						const res = {};
						res.latitude = prepareCoordinate(v.lat);
						res.longitude = prepareCoordinate(v.lng);
						if (!v.radius) {
							res.geo_target = v.id;
							if (v.east && v.north && v.south && v.west) {
								res.east = prepareCoordinate(v.east);
								res.north = prepareCoordinate(v.north);
								res.south = prepareCoordinate(v.south);
								res.west = prepareCoordinate(v.west);
							}
						} else {
							res.radius = Math.round(Number(v.radius));
							res.radius_units = 'KILOMETERS';
						}
						return res;
					});
					break;
			}
		}, {});
	}

}

export class OptimizationAWAccountCampaignAdGroup {

	static TARGETINGS = [
		{key: 'channel', title: 'Channels', tooltip: {
			positive: 'Locations on YouTube where your Google Ads can appear.',
			negative: null
		}},
		{key: 'video', title: 'Videos', tooltip: {
			positive: 'Locations on YouTube where your Google Ads can appear.',
			negative: null
		}},
		{key: 'topic', title: 'Topics', tooltip: {
			positive: 'Topic targeting is an easy way of placing your Google Ads on multiple pages about a specific topic at once.',
			negative: null
		}},
		{key: 'interest', title: 'Interests', tooltip: {
			positive: `Adding audiences allows you to reach people based on their specific interests as they browse pages, videos,<br>
				and content across YouTube and the Google Display Network as well as channels and videos on the YouTube Search Network.`,
			negative: null
		}}
	];

	static fromJSON(json){
		return _.extend(new this(), {
			id: json.id,
			name: json.name,
			maxRate: json.max_rate,
			targeting: _.transform(json.targeting, (acc, val, key) => {
				acc[key] = {
					positive: _.map(val.positive, OptimizationAWAccountCampaignAdGroupTargetingItem.fromJSON.bind(OptimizationAWAccountCampaignAdGroupTargetingItem)),
					negative: _.map(val.negative, OptimizationAWAccountCampaignAdGroupTargetingItem.fromJSON.bind(OptimizationAWAccountCampaignAdGroupTargetingItem))
				};
			}, {}),
			thumbnail: json.thumbnail,
			ads: _.map(json.ad_creations, AWAccountAd.fromJSON.bind(AWAccountAd)),
			ageRanges: json.age_ranges,
			parents: json.parents,
			genders: json.genders,
			updatedAt: dateUtil.dateToLocalZone(json.updated_at)
		});
	}

	static toJSON(data) {
		return _.transform(data, (acc, value, key) => {
			switch(key) {
				case 'name':
					acc['name'] = value;
					break;
				case 'ageRanges':
					acc['age_ranges'] = _.map(value, 'id');
					break;
				case 'genders':
					acc['genders'] = _.map(value, 'id');
					break;
				case 'parents':
					acc['parents'] = _.map(value, 'id');
					break;
				case 'maxRate':
					acc['max_rate'] = Number(value);
					break;
				case 'targeting':
					acc['targeting'] = _.transform(value, (acc, val, key) => {
						acc[key] = {
							positive: _.map(val.positive, 'id'),
							negative: _.map(val.negative, 'id')
						};
					}, {});
					break;
			}
		}, {});
	}

	getTargeting(type, isNegative) {
		return _.get(this, `targeting.${type}.[${isNegative ? 'negative' : 'positive'}]`) || [];
	}

	setTargeting(type, isNegative, newArray) {
		_.set(this, `targeting.${type}.[${isNegative ? 'negative' : 'positive'}]`, newArray);
	}

}

export class AWAccountAd {

	static EXTRA_TAGS = [
		{id: 'beaconImpression1', title: 'Impression 1'}, {id: 'beaconImpression2', title: 'Impression 2'}, {id: 'beaconImpression3', title: 'Impression 3'},
		{id: 'beaconView1', title: 'View 1'}, {id: 'beaconView2', title: 'View 2'}, {id: 'beaconView3', title: 'View 3'},
		{id: 'beaconSkip1', title: 'Skip 1'}, {id: 'beaconSkip2', title: 'Skip 2'}, {id: 'beaconSkip3', title: 'Skip 3'},
		{id: 'beaconDcm1', title: 'DCM 1'}, {id: 'beaconDcm2', title: 'DCM 2'}, {id: 'beaconDcm3', title: 'DCM 3'}];

	static fromJSON(json){
		return _.extend(new this(), {
			customParams: json.custom_params,
			displayURL: json.display_url,
			headline: json.headline,
			description1: json.description_1,
			description2: json.description_2,
			businessName: json.business_name,
			shortHeadline: json.short_headline,
			longHeadline: json.long_headline,
			finalURL: json.final_url,
			id: json.id,
			name: json.name,
			companionBanner: json.companion_banner,
			trackingTemplate: json.tracking_template,
			isDisapproved: json.is_disapproved,
			updatedAt: dateUtil.dateToLocalZone(json.updated_at),
			videoURL: json.video_url,
			videoID: json.video_id,
			videoTitle: json.video_title,
			videoDescription: json.video_description,
			videoThumbnail: json.video_thumbnail,
			videoChannelTitle: json.video_channel_title,
			videoAdFormat: json.video_ad_format,
			videoDuration: json.video_duration,
			beaconImpression1: json.beacon_impression_1,
			beaconImpression2: json.beacon_impression_2,
			beaconImpression3: json.beacon_impression_3,
			beaconView1: json.beacon_view_1,
			beaconView2: json.beacon_view_2,
			beaconView3: json.beacon_view_3,
			beaconSkip1: json.beacon_skip_1,
			beaconSkip2: json.beacon_skip_2,
			beaconSkip3: json.beacon_skip_3,
			beaconFirstQuartile1: json.beacon_first_quartile_1,
			beaconFirstQuartile2: json.beacon_first_quartile_2,
			beaconFirstQuartile3: json.beacon_first_quartile_3,
			beaconMidpoint1: json.beacon_midpoint_1,
			beaconMidpoint2: json.beacon_midpoint_2,
			beaconMidpoint3: json.beacon_midpoint_3,
			beaconThirdQuartile1: json.beacon_third_quartile_1,
			beaconThirdQuartile2: json.beacon_third_quartile_2,
			beaconThirdQuartile3: json.beacon_third_quartile_3,
			beaconCompleted1: json.beacon_completed_1,
			beaconCompleted2: json.beacon_completed_2,
			beaconCompleted3: json.beacon_completed_3,
			beaconVast1: json.beacon_vast_1,
			beaconVast2: json.beacon_vast_2,
			beaconVast3: json.beacon_vast_3,
			beaconDcm1: json.beacon_dcm_1,
			beaconDcm2: json.beacon_dcm_2,
			beaconDcm3: json.beacon_dcm_3
		});
	}

	static toJSON(data) {
		return _.transform(data, (acc, value, key) => {
			switch(key) {
				case 'name':
					acc['name'] = value;
					break;
				case 'videoAdFormat':
					acc['video_ad_format'] = value ? value.id : null;
					break;
				case 'displayURL':
					acc['display_url'] = value;
					break;
				case 'headline':
					acc['headline'] = value;
					break;
				case 'description1':
					acc['description_1'] = value;
					break;
				case 'description2':
					acc['description_2'] = value;
					break;
				case 'businessName':
					acc['business_name'] = value;
					break;
				case 'shortHeadline':
					acc['short_headline'] = value;
					break;
				case 'longHeadline':
					acc['long_headline'] = value;
					break;
				case 'videoDuration':
					acc['video_duration'] = value;
					break;
				case 'finalURL':
					acc['final_url'] = httpURLToHttps(value);
					break;
				case 'videoURL':
					acc['video_url'] = value;
					break;
				case 'trackingTemplate':
					acc['tracking_template'] = httpURLToHttps(value);
					break;
				case 'videoID':
					acc['video_id'] = value;
					break;
				case 'videoTitle':
					acc['video_title'] = value;
					break;
				case 'videoDescription':
					acc['video_description'] = value;
					break;
				case 'videoThumbnail':
					acc['video_thumbnail'] = value;
					break;
				case 'videoChannelTitle':
					acc['video_channel_title'] = value;
					break;
				case 'companionBanner':
					if(_.isObject(value) && value.file) {
						acc['companion_banner'] = value.file;
					}
					break;
				case 'beaconImpression1':
					acc['beacon_impression_1'] = value;
					break;
				case 'beaconImpression2':
					acc['beacon_impression_2'] = value;
					break;
				case 'beaconImpression3':
					acc['beacon_impression_3'] = value;
					break;
				case 'beaconView1':
					acc['beacon_view_1'] = value;
					break;
				case 'beaconView2':
					acc['beacon_view_2'] = value;
					break;
				case 'beaconView3':
					acc['beacon_view_3'] = value;
					break;
				case 'beaconSkip1':
					acc['beacon_skip_1'] = value;
					break;
				case 'beaconSkip2':
					acc['beacon_skip_2'] = value;
					break;
				case 'beaconSkip3':
					acc['beacon_skip_3'] = value;
					break;
				case 'beaconFirstQuartile1':
					acc['beacon_first_quartile_1'] = value;
					break;
				case 'beaconFirstQuartile2':
					acc['beacon_first_quartile_2'] = value;
					break;
				case 'beaconFirstQuartile3':
					acc['beacon_first_quartile_3'] = value;
					break;
				case 'beaconMidpoint1':
					acc['beacon_midpoint_1'] = value;
					break;
				case 'beaconMidpoint2':
					acc['beacon_midpoint_2'] = value;
					break;
				case 'beaconMidpoint3':
					acc['beacon_midpoint_3'] = value;
					break;
				case 'beaconThirdQuartile1':
					acc['beacon_third_quartile_1'] = value;
					break;
				case 'beaconThirdQuartile2':
					acc['beacon_third_quartile_2'] = value;
					break;
				case 'beaconThirdQuartile3':
					acc['beacon_third_quartile_3'] = value;
					break;
				case 'beaconCompleted1':
					acc['beacon_completed_1'] = value;
					break;
				case 'beaconCompleted2':
					acc['beacon_completed_2'] = value;
					break;
				case 'beaconCompleted3':
					acc['beacon_completed_3'] = value;
					break;
				case 'beaconVast1':
					acc['beacon_vast_1'] = value;
					break;
				case 'beaconVast2':
					acc['beacon_vast_2'] = value;
					break;
				case 'beaconVast3':
					acc['beacon_vast_3'] = value;
					break;
				case 'beaconDcm1':
					acc['beacon_dcm_1'] = value;
					break;
				case 'beaconDcm2':
					acc['beacon_dcm_2'] = value;
					break;
				case 'beaconDcm3':
					acc['beacon_dcm_3'] = value;
					break;
			}
		}, {});
	}

}

export class OptimizationAWAccountCampaignAdGroupTargetingItem {

	static fromJSON(json){
		return _.extend(new this(), {
			id: json.criteria || json.id,
			name: json.name || json.title,
			thumbnail: json.thumbnail || json.thumbnail_image_url
		});
	}

}


export class AWAccountOptimizationToolsItem {

	static fromJSON(json){
		const children = _.map(json.children, AWAccountOptimizationToolsItem.fromJSON.bind(AWAccountOptimizationToolsItem));
		return _.extend(new this(), {
			id: json.id,
			name: json.name,
			type: json.type,
			children: children,
			filteredChildren: children
		});
	}

}

export class AWGeoTarget {

	static fromJSON(json){
		return _.extend(new this(), {
			id: json.id,
			name: json.name,
			type: json.target_type
		});
	}

}

export class AWAccountPerformanceFilters {

	static fromJSON(json){
		return _.extend(new this(), {
			campaigns: _.map(json.campaigns, AWCampaign.fromJSON.bind(AWCampaign)),
			targeting: json.targeting,
			accountFilter: json.group_by,
			start: dateUtil.dateWithoutZone(json.start_date),
			end: dateUtil.dateWithoutZone(json.end_date)
		});
	}

}

export class AWAccountPerformanceKPIFilters {

	static KPIs = [
		{key: 'averageCPV', title: 'Avg. CPV ($)', shortTitle: 'Avg.CPV', statType: 'usd', roundDigits: 3},
		{key: 'averageCPM', title: 'Avg. CPM ($)', shortTitle: 'Avg.CPM', statType: 'usd', roundDigits: 3},
		{key: 'ctr', title: 'CTR(i) (%)', shortTitle: 'CTR(i)', statType: 'percentage', roundDigits: 3},
		{key: 'ctrV', title: 'CTR(v) (%)', shortTitle: 'CTR(v)', statType: 'percentage', roundDigits: 3},
		{key: 'videoViewRate', title: 'View Rate (%)', shortTitle: 'View Rate', statType: 'percentage', roundDigits: 3}
	];

	static fromJSON(json){
		return _.extend(new this(), {
			averageCPM: json.average_cpm,
			averageCPV: json.average_cpv,
			ctr: json.ctr,
			ctrV: json.ctr_v,
			videoViewRate: json.video_view_rate
		});
	}

}

export class AWAccountPerformanceItem {

	static fromJSON(json){
		return _.extend(new this(), {
			adGroup: json.ad_group || {},
			campaign:  json.campaign || {},
			id: _.get(json, 'item.id'),
			name: _.get(json, 'item.name'),
			status: _.get(json, 'campaign.status'),
			averageCPM: json.average_cpm,
			averageCPV: json.average_cpv,
			ctr: json.ctr,
			ctrV: json.ctr_v,
			videoViewRate: json.video_view_rate,
			videoViews: json.video_views,
			clicks: json.clicks,
			cost: json.cost,
			impressions: json.impressions,
			targeting: json.targeting,
			isNegative: json.is_negative
		});
	}

	passesKPI(kpiSettings) {
		return _.every(AWAccountPerformanceKPIFilters.KPIs, kpi => {
			const val = this[kpi.key];
			return val >= kpiSettings[kpi.key];
		});
	}

}

export class AWAccountPerformanceReport {

	static reportStaticItems = [
		{key: 'status', title: 'Status'},
		{key: 'name', title: 'Name'},
		{key: 'campaign.name', title: 'Campaign'},
		{key: 'adGroup.name', title: 'Ad Group'},
		{key: 'targeting', title: 'Targeting'}
	];

	static reportNumeralItems = _.concat(
		AWAccountPerformanceKPIFilters.KPIs,
		[
			{key: 'videoViews', title: 'Views', statType: 'number', roundDigits: 3},
			{key: 'impressions', title: 'Impressions', statType: 'number', roundDigits: 3},
			{key: 'clicks', title: 'Clicks', statType: 'number', roundDigits: 3},
			{key: 'cost', title: 'Spend ($)', statType: 'usd', roundDigits: 3}
		]
	);

	static fromJSON(json) {
		return _.extend(new this(), {
			averageCPM: json.average_cpm,
			averageCPV: json.average_cpv,
			clicks: json.clicks,
			cost: json.cost,
			ctr: json.ctr,
			ctrV: json.ctr_v,
			id: json.id,
			impressions: json.impressions,
			items: _.map(json.items, AWAccountPerformanceItem.fromJSON.bind(AWAccountPerformanceItem)),
			label: json.label,
			passes: json.passes,
			videoViewRate: json.video_view_rate,
			videoViews: json.video_views,
			kpiFilters: json.kpi ? AWAccountPerformanceKPIFilters.fromJSON(json.kpi) : {}
		});
	}

}

export class AWHistoricalData {

	static fromJSON(json) {
		return _.extend(new this(), {
			clicks: json.clicks,
			clicksLastWeek: json.clicks_last_week,
			clicksThisWeek: json.clicks_this_week,
			ctr: json.ctr,
			ctrBottom: json.ctr_bottom,
			ctrTop: json.ctr_top,
			ctrV: json.ctr_v,
			ctrVBottom: json.ctr_v_bottom,
			ctrVTop: json.ctr_v_top,
			impressions: json.impressions,
			videoImpressionsLastWeek: json.impressions_last_week,
			videoImpressionsThisWeek: json.impressions_this_week,
			videoViewRate: json.video_view_rate,
			videoViewRateBottom: json.video_view_rate_bottom,
			videoViewRateTop: json.video_view_rate_top,
			videoViews: json.video_views,
			videoViewsLastWeek: json.video_views_last_week,
			videoViewsThisWeek: json.video_views_this_week,
			averageCPV: json.average_cpv,
			averageCPVBottom: json.average_cpv_bottom,
			averageCPVTop: json.average_cpv_top,
			cpm: json.average_cpm,
			cpmBottom: json.average_cpm_bottom,
			cpmTop: json.average_cpm_top
		});
	}

}

class AWCtaClicks {
	static fromJSON(json) {
		return _.extend(new this(), {
			clicksWebsite: json.clicks_website,
			clicksCallToActionOverlay: json.clicks_call_to_action_overlay,
			clicksAppStore: json.clicks_app_store,
			clicksCards: json.clicks_cards,
			clicksEndCap: json.clicks_end_cap
		})
	}
}

//MEDIA BUYING OPTIMIZATION

export class AWMBAccount { // Account data
	static fromJSON(json) {
		return _.extend(new this(), {
			account: json.account,
			allConversions: json.all_conversions,
			avgCPM: json.average_cpm,
			avgCPV: json.average_cpv,
			brand: json.brand,
			clicks: json.clicks,
			cid: json.cid,
			cost: json.cost,
			costMethod: json.cost_method,
			ctr: json.ctr,
			ctrV: json.ctr_v,
			end: json.end,
			id: json.id,
			impressions: json.impressions,
			isChanged: json.is_changed,
			isDisapproved: json.is_disapproved,
			margin: json.margin,
			name: json.name,
			planCPM: json.plan_cpm,
			planCPV: json.plan_cpc,
			projectedMargin: json.projected_margin,
			sfAccount: json.sf_account,
			startDate: json.start,
			statMaxDate: json.statistic_max_date,
			statMinDate: json.statistic_min_date,
			status: json.status,
			thumbnail: json.thumbnail,
			updatedAt: json.updated_at,
			videoViewRate: json.video_view_rate,
			videoViews: json.video_views,
			weeklyChart: json.weekly_chart
		})
	}
}

export class AWMBCampaign { // Campaign data - displayed in board
	static fromJSON(json) {
		return _.extend(new this(), {
			targetName: json.target_name,
			type: json.type,
			campaignID: json.campaign_id,
			campaignName: json.campaign_name,
			adGroupID: json.ad_group_id,
			adGroupName: json.ad_group_name,
			impressions: json.impressions,
			contractedRate: json.contracted_rate,
			impressionsSOV: json.impressions_share,
			videoViewsSOV: json.video_views_share,
			costSOV: json.cost_share,
			avgCPM: json.average_cpm,
			avgCPV: json.average_cpv,
			videoViews: json.video_views,
			clicks: json.clicks,
			cost: json.cost,
			ctrI: json.ctr_i,
			ctrV: json.ctr_v,
			viewRate: json.view_rate,
			profit: json.profit,
			margin: json.margin,
			maxCPV: json.max_cpv,
			maxCPM: json.max_cpm,
			dailyBudget: json.daily_budget,
			status: 'running'
		})
	}
}

export class AWMBGrandSummary { // Grand Summary - targetin data displayed bottom of board
	static fromJSON(json) {
		return _.extend(new this(), {
			impressions: json.impressions,
			contractedRate: json.contracted_rate,
			impressionsSOV: json.impressions_share,
			avgCPM: json.average_cpm,
			avgCPV: json.average_cpv,
			videoViews: json.video_views,
			clicks: json.clicks,
			cost: json.cost,
			ctrI: json.ctrI,
			ctrV: json.ctrV,
			viewRate: json.view_rate,
			profit: json.profit,
			margin: json.margin
		})
	}
}
