import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import Error from '../Error';
import Input from '../markup/Input';
import Button from '../markup/Button';
import TextArea from '../markup/TextArea';
import Dropdown from '../markup/Dropdown';
import { Modal, ModalHeader } from '../markup/Modal';
import { parseFormError, validateEmail } from '../../utils/forms';
import $ from 'jquery';
import AuthDataProvider from './AuthDataProvider';
import UtilsDataProvider from '../common/UtilsDataProvider';

class ContactUsModal extends ShallowRenderedComponent {

	static propTypes = {
		id: PropTypes.string.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			firstName: '',
			lastName: '',
			email: '',
			company: '',
			message: '',
			country: null,
			countries: [],
			error: {},
			showSuccessMessage: false,
			...this.state
		}
	}

	componentDidMount() {
		super.componentDidMount();
		this.trackPromise(UtilsDataProvider.getCountriesList())
			.then(countries => this.setState({ countries }))
			.catch(e => this.showErrorModal('Error while fetching countries list', e));
	}

	closeModal = () => {
		const { id } = this.props;
		$(`#${id}`).modal('hide');
		this.setState({showSuccessMessage: false});
	};

	onSend = () => {
		const { firstName, lastName, email, company, country, message } = this.state;
		const error = {};
		if(_.isEmpty(firstName)) {
			error['first_name'] = 'This field is required';
		}
		if(_.isEmpty(lastName)) {
			error['last_name'] = 'This field is required';
		}
		if(_.isEmpty(email)) {
			error['email'] = 'This field is required';
		}
		if(!validateEmail(email)) {
			error['email'] = 'Please enter valid email';
		}
		if(_.isEmpty(company)) {
			error['company'] = 'This field is required';
		}
		if(!country) {
			error['country'] = 'Please select country';
		}
		this.setState({ error });
		if(!_.isEmpty(error)) {
			return;
		}
		return this.trackPromise(AuthDataProvider.submitContactForm({firstName, lastName, email, company, country: country.id, message}))
			.then(() => {
				this.setState({showSuccessMessage: true});
				this.clear();
			})
			.catch(error => this.setState({ error }));
	};

	clear = () => {
		this.setState({firstName: '', lastName: '', email: '', company: '', message: '', country: null, error: {}});
	};

	render() {
		const { id } = this.props;
		const { firstName, lastName, email, company, message, country, countries, error, showSuccessMessage } = this.state;
		const formError = parseFormError(error);
		return (
			<Modal id={id} noScroll={true} noCloseOutside={true} onShow={this.clear}>
				<ModalHeader>
					<span className="close" onClick={this.closeModal} aria-hidden="true">×</span>
					<div className="title">GET IN TOUCH</div>
					<hr className="v-mar-0"/>
				</ModalHeader>
				<div id="contact-form">
					{formError.generalError && <div className="text-red mb-10"><Error error={formError.generalError}/></div>}
					<div className="row">
						<div className="col-md-12">
							{showSuccessMessage && <div className="alert-success v-mar-10 pad-5 text-center">
								Thank you! Your message has been sent successfully.</div>}
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="row-title">First Name (required)</div>
							<Input maxLength={255} value={firstName}
								   onChange={event => this.setState({firstName: event})} error={formError.first_name}/>
						</div>
						<div className="col-md-6">
							<div className="row-title">Last Name (required)</div>
							<Input maxLength={255} value={lastName}
								   onChange={event => this.setState({lastName: event})} error={formError.last_name}/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="row-title">Email (required)</div>
							<Input maxLength={255} value={email}
								   onChange={event => this.setState({email: event})} error={formError.email}/>
						</div>
						<div className="col-md-6">
							<div className="row-title">Company (required)</div>
							<Input maxLength={255} value={company}
								   onChange={event => this.setState({company: event})} error={formError.company}/>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<div className="row-title">Country (required)</div>
							<div className="full-width">
								<Dropdown className="btn-default btn-with-border" title={country ? country.title : 'Please Select'}
										  maxMenuHeight={350} showFiltering={true} currentOptionsIDs={country ? country.id : null}
										  options={countries} onSelect={opt => this.setState({country: opt})} error={formError.country}/>
							</div>
							<div className="mt-25 full-width">
								<Button className="btn-primary" pendingTitle="Sending..." onClick={this.onSend}>Send</Button>
							</div>
						</div>
						<div className="col-md-6">
							<div className="row-title">Message</div>
							<TextArea maxLength={255} rows={5} className="" value={message}
									  onChange={event => this.setState({message: event})} error={formError.message}/>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

export default ContactUsModal;
