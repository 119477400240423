import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import Promise from 'bluebird';
import Hotjar from '@hotjar/browser';

Promise.config({
	cancellation: true,
	longStackTraces: false,
	warnings: false
});

const siteId = window.location.origin=='https://rc.viewiq.com'?4948609:3741179;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

ReactDOM.render(<div>
	<StrictMode>
		<Routes />
	</StrictMode>
</div>, document.getElementById('app'));
