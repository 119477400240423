import React, { PureComponent } from 'react';
import Parser from 'html-react-parser';
import PropTypes from 'prop-types';
import _ from 'lodash';
import UserManager from '../core/UserManager';
import cx from 'classnames';
import { stringifyError } from '../utils/forms';
import Button from './markup/Button';
import { Link } from 'react-router-dom';
import UtilsDataProvider from 'components/common/UtilsDataProvider';

export class Error extends PureComponent {

	static propTypes = {
		error: PropTypes.any
	};

	static defaultProps = {
		error: {}
	};

	constructor(props){
		super(props);
		this.state = {
			isExpanded: false,
			errorWasSent: false
		};
	}

	sendErrorReport(report) {
		return UtilsDataProvider.sendErrorReport(UserManager.user.email, report)
			.then(() => this.setState({errorWasSent: true}))
			.catch(err => {
				/*eslint-disable no-console */
				console.warn(err)
				/*eslint-enable no-console */
			});
	}

	render() {
		const { isExpanded, errorWasSent } = this.state;
		const { error } = this.props;
		if(!error) {
			return null;
		}
		/*eslint-disable no-console */
		console.warn(error);
		/*eslint-enable no-console */
		let details = null;
		let message = 'Some error occurred. Please try again later.';
		let xhrError = null;
		if(_.isObject(error.xhrError)) {
			xhrError = error.xhrError;
		} else if(error.responseStatus){
			xhrError = error;
		} else if(_.isString(error.xhrError)) {
			message = error.xhrError;
		}
		if(xhrError) {
			let {responseText, responseStatus, textStatus, json, url, method} = xhrError;
			switch (responseStatus) {
				case 401:
					message = <span>Unauthorized request. Try to <Link to="/login" query={{from: window.location.href}}>Login</Link> first.</span>;
					break;
				case 403:
					message = 'Action Forbidden';
					break;
				case 404:
					message = 'Data Not Found';
					break;
				case 408:
					message = 'Request Timeout';
					break;
				case 500:
					message = _.isString(stringifyError(json)) ? stringifyError(json) : 'Server Error';
					break;
				case 502:
					message = 'Bad Gateway';
					break;
				case 503:
					message = 'Service Unavailable';
					break;
				case 504:
					message = 'Request Timeout';
					break;
				default:
					message = stringifyError(json);
					break;
			}

			if(responseStatus && process.env.NODE_ENV === 'development') {
				details = (
					<div className='flex-centered-column'>
						<Button className="btn-primary mb-10" onClick={() => this.setState({isExpanded: !isExpanded})}>Details...</Button>
						<div className={cx({hidden: !isExpanded})}>
							<div className='fw-500'>
								URL: <span className='fw-400'>{url || '-'}</span>&nbsp;
								Method: <span className='fw-400'>{method || 'javascript error'}</span>&nbsp;
								Code: <span className='fw-400'>{responseStatus}</span>&nbsp;
								{textStatus && <span>Text status: <span className='fw-400'>{textStatus}</span></span>}
							</div>
							{responseText && <div className='fw-500'>Response text: <br/><span className="response-text fw-400">{responseText}</span></div>}
							{json && <div className='fw-500'>Data: <span className='fw-400'>{JSON.stringify(json)}</span></div>}
						</div>
					</div>
				);
			} else if (process.env.NODE_ENV === 'production' || UserManager.user.isAdminUser) {
				const report = `URL: ${window.location.href || '-'}. \nCode: ${responseStatus} - ${textStatus}. \nResponse text: ${error.readableMessage || '-'} // ${responseText}. \nStack: ${error.xhrError ? error.xhrError.stack : '-'}`;
				details = xhrError.noReportButton ? null : (
					<div className='flex-centered-column'>
						<Button className="btn-primary mb-10"
								pendingTitle="Sending..."
								disabled={errorWasSent}
								onClick={() => this.sendErrorReport(report)}>
							Send error report
						</Button>
					</div>
				);
			}
		} else {
			message = _.isString(error.xhrError) ? error.xhrError : stringifyError(error);
		}
		return (
			<div className="rendered-error">
				{error.readableMessage && <span className='error-head'>{error.readableMessage}</span>}
				{message && <span className='error-message'>{Parser(message)}</span>}
				{details}
			</div>
		);
	}

}

export default Error;
