import BaseDataProvider from '../../core/BaseDataProvider';

class ExportsManagerDataProvider extends BaseDataProvider {

    getExportsNotifications() {
        return this.get('/api/v1/export_manager/notifications/');
    }

    getExportsList(params) {
        return this.get(`/api/v1/export_manager/?${this.objectToQueryString(params)}`)
    }

    getExportDownloadURL(id) {
        return this.get(`/api/v1/export_manager/${id}/download/`);
    }

    deleteExport(id) {
        return this.delete(`/api/v1/export_manager/${id}/`);
    }

    updateExportTitle(id, data) {
        return this.patch(`/api/v1/export_manager/${id}/`, data);
    }

}

const provider = new ExportsManagerDataProvider();
export default provider;
