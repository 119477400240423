import React from 'react';
import ShallowRenderedComponent from '../base/ShallowRenderedComponent';
import Button from '../markup/Button';
import { ModalHeader, Modal } from '../markup/Modal';
import AuthDataProvider from './AuthDataProvider';
import $ from 'jquery';
import PhoneInputField from '../markup/PhoneInputField';
import UserManager from '../../core/UserManager';
import { isValidPhoneNumber } from 'react-phone-number-input';
import _ from 'lodash';

class NumberInputModal extends ShallowRenderedComponent {
	constructor(props) {
		super(props);
		this.state = {
			phoneNumber: '',
			onUpdatingNumber: false,
			phoneError: ''
		};
	}

	componentDidMount() {
		const { showOnLoad } = this.props;
		if (showOnLoad) $('#number-input-modal').modal('show');
	}

	onChange = () => number => {
		const error = isValidPhoneNumber(`${number}`) ? '' : 'Invalid phone number';
		return this.setState({ phoneError: error, phoneNumber: number });
	};

	onUpdateNumber = () => {
		const { phoneNumber } = this.state;
		return this.setState({ onUpdatingNumber: true }, () => {
			return AuthDataProvider.updateCurrentUser({ phone_number: phoneNumber })
				.then(() => {
					this.onHideModal('updated');
					this.setState({ onUpdatingNumber: false });
				})
				.catch(errors => this.setState({ errors: errors, phoneError: 'Error updating your phone number', onUpdatingNumber: false }));
		});
	};

    onNumberUpdated = () => {
        UserManager.user.phoneNumber = this.state.phoneNumber;
    };

	onHideModal = (type='') => {
		$('#number-input-modal').modal('hide');
		if (type=='skip') this.setState({ phoneNumber: '', phoneError: '' });
		if (type=='updated') this.props.onChange ? this.props.onChange('phoneNumber', this.state.phoneNumber) : this.onNumberUpdated();
		document.cookie = `${UserManager.user.email}=phone_popup`;
	}

	render() {
		const { phoneNumber, onUpdatingNumber, phoneError } = this.state;
		const previousPhoneNumber = UserManager.user.phoneNumber;
		return (
			<Modal id="number-input-modal" noScroll={true} onShow={() => { setTimeout(() => $('.PhoneInputInput').focus(), 500) }} noCloseOutside={true}>
				<ModalHeader>
					<div className="num-header">Improve your account security</div>
				</ModalHeader>
				<div className="main-body">
					<span className="text">
						Providing your phone number allows us to keep your
						account secure and recoverable.
					</span>
					<PhoneInputField onChange={this.onChange()} value={phoneNumber} placeholder={previousPhoneNumber || 'Enter phone number'} />
					{!_.isEmpty(phoneError) && <span className='phone-error'>{phoneError}</span>}
					<Button className="login-button-black" onClick={() => this.onUpdateNumber()} disabled={!_.isEmpty(phoneError)}>
						{onUpdatingNumber ? 'Sending...' : 'Submit'}
					</Button>
					<span className='skip' onClick={() => this.onHideModal('skip')} data-dismiss='modal'>Skip for now</span>
				</div>
			</Modal>
		);
	}
}

export default NumberInputModal;
