import _ from 'lodash';
import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import GlobalConst from '../../utils/GlobalConst';
const shallowCompare = require('react-addons-shallow-compare');
const shallowEqual = require('fbjs/lib/shallowEqual');

class ShallowRenderedComponent extends Component {

	constructor(props) {
		super(props);
		this.mounted = false;
		this.wasMounted = false;
		this.propsToOmitOnUpdate = null;
		this.pendingPromises = [];
	}

	shouldComponentUpdate(nextProps, nextState) {
		if(!this.propsToOmitOnUpdate) {
			return shallowCompare(this, nextProps, nextState);
		}
		return !shallowEqual(this.state, nextState) || !shallowEqual(_.omit(this.props, this.propsToOmitOnUpdate), _.omit(nextProps, this.propsToOmitOnUpdate));
	}

	componentDidMount() {
		this.mounted = true;
		this.wasMounted = true;
	}

	componentWillUnmount() {
		this.mounted = false;
		this.cancelAllPromises();
	}

	trackPromise(promise) {
		this.pendingPromises.push(promise);
		const removePromiseFromPending = promise => {
			this.pendingPromises = _.without(this.pendingPromises, promise);
		};
		return promise.finally(() => removePromiseFromPending(promise));
	}

	renderInfoMessage(msg){
		return <div className="info-message">{msg}</div>
	}

	cancelAllPromises() {
		_.each(this.pendingPromises, r => r.cancel());
		this.pendingPromises = [];
	}

	/**
	 * Shows basic modal dialog
	 * @param {number} type - one from GlobalConst.MODAL_TYPE_...
	 * @param {*=} message - modal message
	 * @param {string=} title - modal title
	 * @param {Array=} buttons - array of objects with structure: {title, callback(optional), className(optional)}
	 * @param {object=} extraProps - extra props passed to Modal component, default modal size is Modal.SIZE.NORMAL
	 * @return {void} none
	 */
	showModal = (type, message, title, buttons, extraProps) => {
		PubSub.publish(GlobalConst.SHOW_MODAL_MSG, {type, message, title, buttons, extraProps});
	};

	showInfoMessage = (message, title = null, buttons = null)  => {
		this.showModal(GlobalConst.MODAL_TYPE_SUCCESS, message, title, buttons);
	};

	showErrorModal = (readableMessage, error, title = null) => {
		/*eslint-disable no-console */
		process.env.NODE_ENV !== 'development' && console.warn(error);
		/*eslint-enable no-console */
		this.showModal(GlobalConst.MODAL_TYPE_ERROR, {xhrError: error, readableMessage}, title);
	};

	showContactUsModal() {
		this.showModal(GlobalConst.MODAL_TYPE_CONTACT_US);
	}

	setBreadcrumbsVariables = breadcrumbs => {
		PubSub.publish(GlobalConst.SET_BREADCRUMBS_MSG, breadcrumbs);
	};

}

export default ShallowRenderedComponent;
