import BaseDataProvider from '../../core/BaseDataProvider';
import { NotificationItem, UserNotificationItem } from '../../models/NotificationsModels';
import _ from 'lodash';

class NotificationsManagerDataProvider extends BaseDataProvider {

    getNotificationsOptions() {
        return this.get('/api/v1/notification/options/');
    }

	getNotifications(page, enabled=false) {
		return this.get(`/api/v1/notification/?page=${page}&is_enabled=${enabled}`)
			.then(data => this.deserializePagedResponse(data, NotificationItem));
	}

	createNotification(notification) {
		return this.post('/api/v1/notification/', NotificationItem.toJSON(notification));
	}

	updateNotification(notification) {
		return this.patch(`/api/v1/notification/?notification=${notification.id}`, NotificationItem.toJSON(notification));
	}

	onReadNotification(notificationID) {
		return this.patch(`/api/v1/user_notification/${notificationID}/`, { is_read: true });
	}

	getUserNotifications() {
		return this.get('/api/v1/user_notification/list/?platform=2')
			.then(data => _.map(data, notification => UserNotificationItem.fromJSON(notification)));
	}
	
	updateUserNotification(id='all') {
		return this.patch(`/api/v1/user_notification/${id}/`);
	}

}

const provider = new NotificationsManagerDataProvider();
export default provider;
